import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import React, { useContext, useEffect, useState } from "react";
import * as web3 from "@solana/web3.js";
import oldStyles from "./oldStyles";
import { commafy, origin } from "./utils";
import Holding from "./Holding";
import AppContext from "./AppContext";
import { css, StyleSheet } from "aphrodite";
import { PhantomWalletName } from "@solana/wallet-adapter-phantom";
import Holding2 from "./Holding2";
import TradingViewWidget from "./TradingViewWidget";
import TraderCoin from "./TraderCoin";

const styles = StyleSheet.create({
  primaryButton: {
    background: "rgb(32 129 226)",
    borderRadius: "20px",
    padding: "10px 20px",
    fontWeight: "900",
    textAlign: "center",
    transition: "0.3s all",
    cursor: "pointer",
    ":hover": {
      backgroundColor: "rgb(69, 145, 222)",
    },
  },
});

const PriceChartWidget = ({ mint }) => {
  if (!mint) return;
  useEffect(() => {
    function loadWidget() {
      if (typeof window.createMyWidget === "function") {
        window.createMyWidget("price-chart-widget-container", {
          autoSize: true,
          chainId: "solana",
          tokenAddress: mint,
          defaultInterval: "1",
          timeZone:
            Intl.DateTimeFormat().resolvedOptions().timeZone ?? "Etc/UTC",
          theme: "moralis",
          locale: "en",
          backgroundColor: "#222329",
          gridColor: "#343640",
          textColor: "#68738D",
          candleUpColor: "#4CE666",
          candleDownColor: "#E64C4C",
          hideLeftToolbar: false,
          hideTopToolbar: false,
          hideBottomToolbar: false,
        });
      } else {
        console.error("createMyWidget function is not defined.");
      }
    }

    if (!document.getElementById("moralis-chart-widget")) {
      const script = document.createElement("script");
      script.id = "moralis-chart-widget";
      script.src = "https://moralis.com/static/embed/chart.js";
      script.type = "text/javascript";
      script.async = true;
      script.onload = loadWidget;
      document.body.appendChild(script);
    } else {
      loadWidget();
    }
  }, [mint]);

  if (!mint) {
    return null;
  }

  return (
    <div
      id="price-chart-widget-container"
      style={{ width: "100%", height: "700px" }}
    ></div>
  );
};

const GeckoTerminalEmbed = ({ mintRecord }) => {
  if (!mintRecord?.accountId) {
    return null;
  }

  return (
    <iframe
      height="700px"
      width="100%"
      id="geckoterminal-embed"
      title="GeckoTerminal Embed"
      src={`https://www.geckoterminal.com/solana/pools/${mintRecord.accountId}?embed=1&info=0&swaps=0&grayscale=0&light_chart=0&chart_type=price&resolution=1m`}
      frameBorder="0"
      allow="clipboard-write"
      allowFullScreen
    ></iframe>
  );
};

const TokenScreen = ({}) => {
  const { coinSelected, setCoinSelected, authToken, tokenHoldings, holdings } =
    useContext(AppContext);

  const [tokenData, setTokenData] = useState(null);
  const [mintRecord, setMintRecord] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [buyAmount, setBuyAmount] = useState(0.1);
  const [sellPercentage, setSellPercentage] = useState(() => {
    const storedSellPercentage = localStorage.getItem("sellPercentage");
    return storedSellPercentage !== null
      ? parseFloat(storedSellPercentage)
      : 100;
  });

  useEffect(() => {
    const relevantTokenHolding = tokenHoldings.find(
      (h) => h?.mint === tokenData?.mint
    );
    if (relevantTokenHolding) {
      setTokenData(relevantTokenHolding);
    }
  }, [tokenHoldings]);
  useEffect(() => {
    const relevantHolding = holdings.find(
      (h) => h?.mint === coinSelected?.mint
    );
    if (relevantHolding) {
      setCoinSelected(relevantHolding);
    }
  }, [holdings]);

  useEffect(() => {
    localStorage.setItem("sellPercentage", sellPercentage);
  }, [sellPercentage]);

  useEffect(() => {
    if (!coinSelected?.mint || !tokenData) {
      const controller = new AbortController(); // Create an AbortController
      const signal = controller.signal; // Get the signal from the controller

      (async function () {
        try {
          setFetching(true);

          const headers = {
            "Content-Type": "application/json",
          };

          if (authToken) {
            headers["Authorization"] = `Bearer ${localStorage.getItem(
              "authToken"
            )}`;
          }

          const response = await fetch(
            `${origin}/token?mint=${coinSelected.mint}`,
            {
              method: "GET",
              headers,
              signal, // Pass the signal to fetch
            }
          );

          if (!response.ok) {
            console.error("Failed to fetch tokens.");
            setTokenData(null);
            setMintRecord(null);
            setFetching(false);
            return;
          }

          const { tokenHolding, token, mintRecord: mr } = await response.json();
          setMintRecord(mr);
          setTokenData(tokenHolding);
          setCoinSelected(token);
          setFetching(false);
        } catch (error) {
          if (error.name === "AbortError") {
            console.log("Fetch aborted due to coin change.");
          } else {
            console.error("Error fetching tokens:", error);
          }
          setTokenData(null);
          setFetching(false);
        }
      })();

      // Cleanup function to cancel the previous request
      return () => {
        controller.abort(); // This cancels the previous request when coinSelected.mint changes
      };
    }
  }, [authToken, coinSelected, tokenData]);

  if (!coinSelected?.tokenMetadata) {
    return <></>;
  }

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <div
        style={{
          ...oldStyles.box,
          flex: 1,
          flexGrow: 1,
          overflowY: "scroll",
          scrollbarWidth: "none",
          boxSizing: "border-box",
          minWidth: "100%",
          minHeight: "400px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "50px",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "inline-flex",
              gap: "15px",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <div>
              <div
                style={{
                  fontSize: "12px",
                  fontWeight: "600",
                  minWidth: "110px",
                }}
              >
                🏷️ Sell Percentage (%)
              </div>
              <div
                style={{
                  fontSize: "12px",
                  color: "rgba(255, 255, 255, 0.6)",
                  marginTop: "5px",
                }}
              >
                What % to sell
              </div>
            </div>
            <div
              style={{
                border: "1px solid rgba(255, 255, 255, 0.05)",
                backgroundColor: "rgba(0, 0, 0, 0.1)",
                display: "flex",
                gap: "5px",
                padding: "10px",
                position: "relative",
                width: "120px",
              }}
            >
              <input
                style={{ color: "white" }}
                placeholder="0"
                value={sellPercentage || ""}
                onChange={(e) => {
                  const value = e.target.value;

                  // Allow only numbers and a single decimal point
                  if (/^\d*\.?\d*$/.test(value)) {
                    setSellPercentage(value);
                  }
                }}
              />
            </div>
          </div>
          <div
            style={{
              display: "inline-flex",
              gap: "15px",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <div>
              <div style={{ fontSize: "12px", fontWeight: "600" }}>
                Buy Amount Sol
              </div>
              <div
                style={{
                  fontSize: "12px",
                  color: "rgba(255, 255, 255, 0.6)",
                  marginTop: "5px",
                }}
              >
                Enter Sol Amount
              </div>
            </div>
            <div
              style={{
                border: "1px solid rgba(255, 255, 255, 0.05)",
                backgroundColor: "rgba(0, 0, 0, 0.1)",
                display: "flex",
                gap: "5px",
                padding: "10px",
                width: "120px",
              }}
            >
              <img
                style={{ borderRadius: "100%" }}
                width={20}
                height={20}
                src={`${window.location.origin}/sol.png`}
              />
              <input
                style={{ color: "white" }}
                placeholder="0"
                value={buyAmount || ""}
                onChange={(e) => {
                  const value = e.target.value;

                  // Allow only numbers and a single decimal point
                  if (/^\d*\.?\d*$/.test(value)) {
                    setBuyAmount(value);
                  }
                }}
              />
            </div>
          </div>
        </div>
        <TraderCoin coin={coinSelected} sol={buyAmount} />

        {tokenData && (
          <Holding2
            isMini={false}
            holding={coinSelected}
            tokenHolding={tokenData}
            buyAmount={0}
            sellPercentage={sellPercentage}
          />
        )}
        <div style={{ color: "rgba(255, 255, 255, 0.5)" }}>
          <span
            style={{
              color: "rgba(255, 255, 255, 1)",
              fontWeight: "700",
              paddingRight: "5px",
            }}
          >
            Warning:
          </span>
          The chart below is delayed by a few seconds, use the mini chart to
          view pricing based off of processing transactions
        </div>
        {mintRecord && <GeckoTerminalEmbed mintRecord={mintRecord} />}
        {!mintRecord && <PriceChartWidget mint={coinSelected.mint} />}

        {/* <div style={{ position: "relative", minHeight: "600px" }}>
          <div
            style={{
              position: "absolute",
              inset: 0,
              filter: "blur(5px)",
            }}
          >
            <TradingViewWidget />
          </div>

          <div
            style={{
              position: "absolute",
              inset: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              color: "white",
              fontSize: "24px",
              fontWeight: "bold",
            }}
          >
            Live charts coming soon
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default TokenScreen;
