import React, { useContext, useState, useEffect } from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import { css, StyleSheet } from "aphrodite";
import oldStyles from "./oldStyles";
import Toast from "./Toast";
import AppContext from "./AppContext";

import Filter from "./Filter";

const FILTERS_LOCAL_STORAGE_KEY = "filters";

let toastId = 1;

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    maxHeight: "50vh", // Doubled to 800px
    overflowY: "auto",
    overflowX: "hidden",
    position: "relative", // Add this
    // Hide scrollbars (WebKit-based browsers):
    "::webkit-scrollbar": {
      width: 0,
      height: 0,
      background: "transparent",
    },
    // For Firefox (optional):
    scrollbarWidth: "none",
  },
  buttonContainer: {
    position: "sticky", // Add this
    bottom: 0, // Add this
    background: "rgb(34 35 41)", // Add this, change to your desired background color
    zIndex: 1, // Add this, adjust the value as needed
    padding: "10px 0 0", // Add this, adjust the value as needed
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  primaryButton: {
    background: "rgb(32 129 226)",
    borderRadius: "20px",
    padding: "10px 20px",
    fontWeight: "900",
    textAlign: "center",
    transition: "0.3s all",
    cursor: "pointer",
    ":hover": {
      backgroundColor: "rgb(69, 145, 222)",
    },
  },
});

const Filters = ({ display, setDisplay }) => {
  const { addFilters, socket } = useContext(AppContext);

  // Default values
  const DEFAULT_FROM_MARKETCAP = 10000;
  const DEFAULT_TO_MARKETCAP = "";
  const DEFAULT_FROM_SUCCESS = "";
  const DEFAULT_TO_SUCCESS = "";
  const DEFAULT_FROM_SCAM = "";
  const DEFAULT_TO_SCAM = "";
  const DEFAULT_FROM_VOLUME = "";
  const DEFAULT_TO_VOLUME = "";
  const DEFAULT_FROM_AGE = "";
  const DEFAULT_TO_AGE = 180;
  const DEFAULT_FROM_SMART_MONEY = "";
  const DEFAULT_TO_SMART_MONEY = "";
  const DEFAULT_FROM_WHALE = "";
  const DEFAULT_TO_WHALE = "";
  const DEFAULT_FROM_NEW_WALLET = "";
  const DEFAULT_TO_NEW_WALLET = "";
  const DEFAULT_FROM_BOT = "";
  const DEFAULT_TO_BOT = "";

  // States
  const [fromMarketCap, setFromMarketCap] = useState(DEFAULT_FROM_MARKETCAP);
  const [toMarketCap, setToMarketCap] = useState(DEFAULT_TO_MARKETCAP);

  const [fromSuccessChance, setFromSuccessChance] =
    useState(DEFAULT_FROM_SUCCESS);
  const [toSuccessChance, setToSuccessChance] = useState(DEFAULT_TO_SUCCESS);

  const [fromScamPercentage, setFromScamPercentage] =
    useState(DEFAULT_FROM_SCAM);
  const [toScamPercentage, setToScamPercentage] = useState(DEFAULT_TO_SCAM);

  const [fromVolume, setFromVolume] = useState(DEFAULT_FROM_VOLUME);
  const [toVolume, setToVolume] = useState(DEFAULT_TO_VOLUME);

  const [fromAge, setFromAge] = useState(DEFAULT_FROM_AGE);
  const [toAge, setToAge] = useState(DEFAULT_TO_AGE);

  const [fromSmartMoney, setFromSmartMoney] = useState(
    DEFAULT_FROM_SMART_MONEY
  );
  const [toSmartMoney, setToSmartMoney] = useState(DEFAULT_TO_SMART_MONEY);

  const [fromWhale, setFromWhale] = useState(DEFAULT_FROM_WHALE);
  const [toWhale, setToWhale] = useState(DEFAULT_TO_WHALE);

  const [fromNewWallet, setFromNewWallet] = useState(DEFAULT_FROM_NEW_WALLET);
  const [toNewWallet, setToNewWallet] = useState(DEFAULT_TO_NEW_WALLET);

  const [fromBot, setFromBot] = useState(DEFAULT_FROM_BOT);
  const [toBot, setToBot] = useState(DEFAULT_TO_BOT);
  const [loadedSavedFilters, setLoadedSavedFilters] = useState(false);

  // Optional context usage
  const wallet = useWallet();
  const { someContextValue } = useContext(AppContext);

  // Load saved filters from localStorage on mount
  useEffect(() => {
    const savedFilters = localStorage.getItem(FILTERS_LOCAL_STORAGE_KEY);
    if (savedFilters) {
      const parsedFilters = JSON.parse(savedFilters);

      // Only update state if property is defined in localStorage
      if (typeof parsedFilters.fromMarketCap !== "undefined") {
        setFromMarketCap(parsedFilters.fromMarketCap);
      }
      if (typeof parsedFilters.toMarketCap !== "undefined") {
        setToMarketCap(parsedFilters.toMarketCap);
      }

      if (typeof parsedFilters.fromSuccessChance !== "undefined") {
        setFromSuccessChance(parsedFilters.fromSuccessChance);
      }
      if (typeof parsedFilters.toSuccessChance !== "undefined") {
        setToSuccessChance(parsedFilters.toSuccessChance);
      }

      if (typeof parsedFilters.fromScamPercentage !== "undefined") {
        setFromScamPercentage(parsedFilters.fromScamPercentage);
      }
      if (typeof parsedFilters.toScamPercentage !== "undefined") {
        setToScamPercentage(parsedFilters.toScamPercentage);
      }

      if (typeof parsedFilters.fromVolume !== "undefined") {
        setFromVolume(parsedFilters.fromVolume);
      }
      if (typeof parsedFilters.toVolume !== "undefined") {
        setToVolume(parsedFilters.toVolume);
      }

      if (typeof parsedFilters.fromAge !== "undefined") {
        setFromAge(parsedFilters.fromAge);
      }
      if (typeof parsedFilters.toAge !== "undefined") {
        setToAge(parsedFilters.toAge);
      }

      if (typeof parsedFilters.fromSmartMoney !== "undefined") {
        setFromSmartMoney(parsedFilters.fromSmartMoney);
      }
      if (typeof parsedFilters.toSmartMoney !== "undefined") {
        setToSmartMoney(parsedFilters.toSmartMoney);
      }

      if (typeof parsedFilters.fromWhale !== "undefined") {
        setFromWhale(parsedFilters.fromWhale);
      }
      if (typeof parsedFilters.toWhale !== "undefined") {
        setToWhale(parsedFilters.toWhale);
      }

      if (typeof parsedFilters.fromNewWallet !== "undefined") {
        setFromNewWallet(parsedFilters.fromNewWallet);
      }
      if (typeof parsedFilters.toNewWallet !== "undefined") {
        setToNewWallet(parsedFilters.toNewWallet);
      }

      if (typeof parsedFilters.fromBot !== "undefined") {
        setFromBot(parsedFilters.fromBot);
      }
      if (typeof parsedFilters.toBot !== "undefined") {
        setToBot(parsedFilters.toBot);
      }
    }
    setLoadedSavedFilters(true);
  }, []);

  const buildFilters = () => {
    const newFilters = [];

    const pushFilter = (field, operator, val) => {
      if (val !== "" && val != null && !isNaN(val)) {
        newFilters.push({
          field,
          operator,
          value: Number(val),
        });
      }
    };

    // Market Cap
    pushFilter("marketCap", "gte", parseInt(fromMarketCap));
    pushFilter("marketCap", "lte", parseInt(toMarketCap));

    // Success Chance
    pushFilter("successChance", "gte", parseFloat(fromSuccessChance) / 100);
    pushFilter("successChance", "lte", parseFloat(toSuccessChance) / 100);

    // Scam
    pushFilter("scamPercentage", "gte", parseFloat(fromScamPercentage) / 100);
    pushFilter("scamPercentage", "lte", parseFloat(toScamPercentage) / 100);

    // Volume
    pushFilter("volume", "gte", parseInt(fromVolume));
    pushFilter("volume", "lte", parseInt(toVolume));

    // Age
    pushFilter("createdAt", "lte", parseInt(fromAge)); // "from" should be gte
    pushFilter("createdAt", "gte", parseInt(toAge)); // "to" should be lte

    // Smart Money
    pushFilter("insiderPercentage", "gte", parseFloat(fromSmartMoney) / 100);
    pushFilter("insiderPercentage", "lte", parseFloat(toSmartMoney) / 100);

    // Whale
    pushFilter("whalePercentage", "gte", parseFloat(fromWhale) / 100);
    pushFilter("whalePercentage", "lte", parseFloat(toWhale) / 100);

    // New Wallet
    pushFilter("newWalletsPercentage", "gte", parseFloat(fromNewWallet) / 100);
    pushFilter("newWalletsPercentage", "lte", parseFloat(toNewWallet) / 100);

    // Bot
    pushFilter("botPercentage", "gte", parseFloat(fromBot) / 100);
    pushFilter("botPercentage", "lte", parseFloat(toBot) / 100);

    return newFilters;
  };

  // Handle Apply (save to localStorage)
  const handleApply = () => {
    const filters = {
      fromMarketCap,
      toMarketCap,
      fromSuccessChance,
      toSuccessChance,
      fromScamPercentage,
      toScamPercentage,
      fromVolume,
      toVolume,
      fromAge,
      toAge,
      fromSmartMoney,
      toSmartMoney,
      fromWhale,
      toWhale,
      fromNewWallet,
      toNewWallet,
      fromBot,
      toBot,
    };
    localStorage.setItem(FILTERS_LOCAL_STORAGE_KEY, JSON.stringify(filters));

    // Build filter objects for backend
    const newFilters = buildFilters();

    // Send them to your backend
    if (addFilters) {
      addFilters(newFilters);
    }

    setDisplay(null);

    // Optionally show a toast confirmation
    // Toast.success("Filters applied!");
  };

  useEffect(() => {
    if (loadedSavedFilters) {
      addFilters(buildFilters());
    }
  }, [loadedSavedFilters]);

  // Handle Reset (reset to default values)
  const handleReset = () => {
    setFromMarketCap(DEFAULT_FROM_MARKETCAP);
    setToMarketCap(DEFAULT_TO_MARKETCAP);

    setFromSuccessChance(DEFAULT_FROM_SUCCESS);
    setToSuccessChance(DEFAULT_TO_SUCCESS);

    setFromScamPercentage(DEFAULT_FROM_SCAM);
    setToScamPercentage(DEFAULT_TO_SCAM);

    setFromVolume(DEFAULT_FROM_VOLUME);
    setToVolume(DEFAULT_TO_VOLUME);

    setFromAge(DEFAULT_FROM_AGE);
    setToAge(DEFAULT_TO_AGE);

    setFromSmartMoney(DEFAULT_FROM_SMART_MONEY);
    setToSmartMoney(DEFAULT_TO_SMART_MONEY);

    setFromWhale(DEFAULT_FROM_WHALE);
    setToWhale(DEFAULT_TO_WHALE);

    setFromNewWallet(DEFAULT_FROM_NEW_WALLET);
    setToNewWallet(DEFAULT_TO_NEW_WALLET);

    setFromBot(DEFAULT_FROM_BOT);
    setToBot(DEFAULT_TO_BOT);

    // Optionally show a toast to confirm reset
    // Toast.info("Filters reset to defaults!");
  };

  if (!display) {
    return <></>;
  }

  return (
    <div
      style={{
        position: "absolute",
        padding: "20px",
        backgroundColor: "rgb(34 35 41)",
        border: "1px solid rgba(255, 255, 255, 0.1)",
        zIndex: 4,
        borderRadius: "20px",
        marginTop: "20px",
      }}
    >
      <div className={css(styles.container)}>
        <Filter
          title="Success Chance (%)"
          from={fromSuccessChance}
          to={toSuccessChance}
          setFrom={setFromSuccessChance}
          setTo={setToSuccessChance}
        />
        <Filter
          title="Market Cap ($)"
          from={fromMarketCap}
          to={toMarketCap}
          setFrom={setFromMarketCap}
          setTo={setToMarketCap}
        />
        <Filter
          title="Scam (%)"
          from={fromScamPercentage}
          to={toScamPercentage}
          setFrom={setFromScamPercentage}
          setTo={setToScamPercentage}
        />
        <Filter
          title="Age (mins)"
          from={fromAge}
          to={toAge}
          setFrom={setFromAge}
          setTo={setToAge}
        />
        <Filter
          title="Volume ($)"
          from={fromVolume}
          to={toVolume}
          setFrom={setFromVolume}
          setTo={setToVolume}
        />
        <Filter
          title="Smart Money (%)"
          from={fromSmartMoney}
          to={toSmartMoney}
          setFrom={setFromSmartMoney}
          setTo={setToSmartMoney}
        />
        <Filter
          title="Whale (%)"
          from={fromWhale}
          to={toWhale}
          setFrom={setFromWhale}
          setTo={setToWhale}
        />
        <Filter
          title="New Wallet (%)"
          from={fromNewWallet}
          to={toNewWallet}
          setFrom={setFromNewWallet}
          setTo={setToNewWallet}
        />
        <Filter
          title="Bot (%)"
          from={fromBot}
          to={toBot}
          setFrom={setFromBot}
          setTo={setToBot}
        />

        <div className={css(styles.buttonContainer)}>
          {/* Right-aligned Reset Button */}
          <div className={css(styles.primaryButton)} onClick={handleReset}>
            Reset
          </div>
          {/* Left-aligned Apply Button */}
          <div className={css(styles.primaryButton)} onClick={handleApply}>
            Apply
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filters;
