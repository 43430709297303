import React from "react";
import ToolTip from "./Tooltip.js";

const holdingGridTemplateColumns =
  "200px minmax(150px, 200px) repeat(4, minmax(100px, 120px)) minmax(150px, 1fr) 110px";

const HoldingsHeader = ({ isPaused }) => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: holdingGridTemplateColumns,
        scrollbarWidth: "none",
        fontSize: "16px",
        position: "relative",
        padding: "5px 15px",
        color: "rgba(255, 255, 255, 0.8)",
        alignItems: "end", // Align grid items at the bottom
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          overflow: "hidden",
        }}
      >
        📖 Tokens
        {isPaused && (
          <span
            style={{
              paddingLeft: "5px",
              fontSize: "14px",
              fontWeight: "900",
              color: "rgba(0, 180, 255, 1)",
            }}
          >
            ⏸︎ PAUSED
          </span>
        )}
      </div>
      {/* <div
        style={{
          color: "white",
          fontSize: "14px",
          paddingLeft: "10px",
          textAlign: "left",
        }}
      >
        🕒 Age
      </div> */}
      <div
        style={{
          color: "white",
          fontSize: "14px",
          textAlign: "left",
        }}
      >
        💵 Invested
      </div>
      {/* <div
        style={{
          color: "white",
          fontSize: "14px",
          paddingLeft: "10px",
          textAlign: "left",
        }}
      >
         Volume
      </div> */}
      <div
        style={{
          color: "white",
          fontSize: "12px",
          textAlign: "left",
        }}
      >
        ⏳ Remaining
      </div>
      <div
        style={{
          color: "white",
          fontSize: "12px",
          textAlign: "left",
        }}
      >
        💰 Amount Sold
      </div>
      <div
        style={{
          color: "white",
          fontSize: "12px",
          textAlign: "left",
        }}
      >
        🔥 Market Cap
      </div>
      <div
        style={{
          color: "white",
          fontSize: "12px",
          textAlign: "left",
        }}
      >
        🚀 Sol P&L
      </div>
      <div
        style={{
          color: "white",
          fontSize: "12px",
          textAlign: "left",
        }}
      >
        🔍 Chart
      </div>
      <div
        style={{
          color: "white",
          fontSize: "14px",
          textAlign: "center",
          alignSelf: "center",
        }}
      >
        🎯 Instant Sell
      </div>
    </div>
  );
};

export default HoldingsHeader;
