import React, { useState, useEffect, useRef } from "react";

const CandleChart = ({ marketCap, bars = [] }) => {
  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(200);

  useEffect(() => {
    const updateWidth = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.offsetWidth - 20); // Leave 20px for labels
      }
    };
    updateWidth();
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  const HEIGHT = 75;
  const BAR_WIDTH = 5;
  const BAR_SPACING = 1;
  const PADDING = 5;
  const MIN_SCALE_PADDING = 3;

  const MAX_BARS = Math.floor(
    (containerWidth - 2 * PADDING) / (BAR_WIDTH + BAR_SPACING)
  );

  const [candles, setCandles] = useState(() => {
    const initialBar = {
      high: marketCap,
      low: marketCap,
      start: marketCap,
      end: marketCap,
    };
    return bars.length > 0 ? bars : [initialBar];
  });

  const [currentCandle, setCurrentCandle] = useState(
    bars.length > 0
      ? {
          high: bars[bars.length - 1].end,
          low: bars[bars.length - 1].end,
          start: bars[bars.length - 1].end,
          end: bars[bars.length - 1].end,
        }
      : {
          high: marketCap,
          low: marketCap,
          start: marketCap,
          end: marketCap,
        }
  );

  const lastUpdateTime = useRef(Date.now());

  useEffect(() => {
    const interval = setInterval(() => {
      const now = Date.now();
      if (now - lastUpdateTime.current >= 1000) {
        setCandles((prev) => [...prev.slice(-MAX_BARS + 1), currentCandle]);
        setCurrentCandle({
          high: marketCap,
          low: marketCap,
          start: marketCap,
          end: marketCap,
        });
        lastUpdateTime.current = now;
      }
    }, 50);
    return () => clearInterval(interval);
  }, [currentCandle, MAX_BARS]);

  useEffect(() => {
    setCurrentCandle((prev) => ({
      ...prev,
      high: Math.max(prev.high, marketCap),
      low: Math.min(prev.low, marketCap),
      end: marketCap,
    }));
  }, [marketCap]);

  const displayedBars = [...candles, currentCandle].filter(Boolean).reverse();

  let minLow = Math.min(...displayedBars.map((b) => b.low));
  let maxHigh = Math.max(...displayedBars.map((b) => b.high));

  if (minLow === maxHigh) {
    minLow -= 0.01;
    maxHigh += 0.01;
  }

  minLow -= (MIN_SCALE_PADDING / HEIGHT) * (maxHigh - minLow);
  maxHigh += (MIN_SCALE_PADDING / HEIGHT) * (maxHigh - minLow);

  const scaleY = (value) =>
    HEIGHT -
    ((value - minLow) / (maxHigh - minLow)) * (HEIGHT - MIN_SCALE_PADDING);

  const formatNumber = (num) =>
    num >= 1000 ? (num / 1000).toFixed(1) + "k" : num.toString();

  const totalBarWidth = BAR_WIDTH + BAR_SPACING;
  const startX = containerWidth - PADDING - 8 - BAR_WIDTH / 2;

  return (
    <div
      ref={containerRef}
      style={{
        width: "100%",
        height: HEIGHT,
        position: "relative",
        display: "flex",
      }}
    >
      <div
        style={{
          width: containerWidth,
          height: HEIGHT,
          background: "rgba(0, 0, 0, 0.3)",
          borderRadius: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingLeft: PADDING,
          paddingRight: PADDING,
        }}
      >
        <svg width={containerWidth - 2 * PADDING} height={HEIGHT}>
          {displayedBars.map((bar, i) => {
            const x = startX - i * totalBarWidth;
            let yHigh = scaleY(bar.high);
            let yLow = scaleY(bar.low);
            let yStart = scaleY(bar.start);
            let yEnd = scaleY(bar.end);
            const isGreen = bar.end >= bar.start;
            const color = isGreen ? "rgba(0, 180, 255, 1)" : "#f44336";

            if (yHigh === yLow) yLow = yHigh + 1;
            if (yStart === yEnd) yEnd = yStart + 1;

            if (Math.abs(yLow - yHigh) < 1) {
              yLow = yHigh + (yHigh >= yLow ? 1 : -1);
            }

            if (Math.abs(yEnd - yStart) < 1) {
              yEnd = yStart + (yStart >= yEnd ? 1 : -1);
            }

            return (
              <g key={i}>
                <line
                  x1={x}
                  x2={x}
                  y1={yHigh}
                  y2={yLow}
                  stroke={color}
                  strokeWidth={1}
                />
                <rect
                  x={x - BAR_WIDTH / 2}
                  y={Math.min(yStart, yEnd)}
                  width={BAR_WIDTH}
                  height={Math.abs(yStart - yEnd)}
                  fill={color}
                />
              </g>
            );
          })}
        </svg>
      </div>
      <div
        style={{
          width: 20,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "flex-start",
          paddingLeft: 5,
          color: "white",
          fontSize: "12px",
        }}
      >
        <div>{formatNumber(maxHigh)}</div>
        <div>{formatNumber(minLow)}</div>
      </div>
    </div>
  );
};

export default CandleChart;
