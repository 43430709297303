import React, { useContext, useEffect, useState } from "react";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { css, StyleSheet } from "aphrodite";
import AppContext from "./AppContext";
import Toast from "./Toast";
import {
  LAMPORTS_PER_SOL,
  Transaction,
  SystemProgram,
  sendAndConfirmTransaction,
  PublicKey,
} from "@solana/web3.js";
import { origin } from "./utils";

let toastId = 1;

const styles = StyleSheet.create({
  primaryButton: {
    background: "rgb(32 129 226)",
    borderRadius: "20px",
    padding: "10px 20px",
    fontWeight: "900",
    minWidth: "400px",
    textAlign: "center",
    transition: "0.3s all",
    cursor: "pointer",
    ":hover": {
      backgroundColor: "rgb(69, 145, 222)",
    },
  },
  input: {
    padding: "10px",
    border: "1px solid #42444f",
    borderRadius: "10px",
    backgroundColor: "#0f1018",
    color: "white",
    fontSize: "16px",
    width: "100%",
  },
  tabContainer: {
    display: "flex",
    gap: "10px",
    justifyContent: "center",
  },
  tab: {
    padding: "10px 20px",
    cursor: "pointer",
    fontWeight: "bold",
    color: "white",
    background: "rgba(255,255,255,0.1)",
    borderRadius: "10px",
    transition: "0.3s",
    ":hover": {
      background: "rgba(255,255,255,0.3)",
    },
  },
  activeTab: {
    background: "rgb(32 129 226)",
  },
});

const withdraw = async (recipient, solAmount) => {
  try {
    const token = localStorage.getItem("authToken");

    if (!token) {
      console.error("No token found. Verify signature first.");
      return;
    }

    const response = await fetch(`${origin}/withdraw`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ recipient, sol_amount: solAmount }),
    });

    const data = await response.json();

    if (response.ok) {
      console.log("Withdraw successful:", data);
    } else {
      console.error("Withdraw failed:", data.error);
    }
  } catch (err) {
    console.error("Error accessing withdraw endpoint:", err);
  }
};

const fetchWallets = async (ownerAddress) => {
  try {
    const response = await fetch(
      `${origin}/wallets?ownerAddress=${ownerAddress}`
    );
    const data = await response.json();

    if (response.ok) {
      return data.publicKeys;
    } else {
      console.error("Error fetching wallets:", data.error);
      return [];
    }
  } catch (err) {
    console.error("Error accessing wallets endpoint:", err);
    return [];
  }
};

const importWallet = async (privateKey) => {
  try {
    const token = localStorage.getItem("authToken");

    if (!token) {
      console.error("No token found. Verify signature first.");
      return;
    }

    const response = await fetch(`${origin}/import_wallet`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ privateKey }),
    });

    const data = await response.json();

    if (response.ok) {
      return data.wallet;
    } else {
      console.error("Failed to import wallet:", data.error);
      alert(`Error: ${data.error}`);
      return null;
    }
  } catch (err) {
    console.error("Error accessing import_wallet endpoint:", err);
    alert("Failed to import wallet.");
    return null;
  }
};

const setWallet = async (publicKey) => {
  try {
    const token = localStorage.getItem("authToken");

    if (!token) {
      console.error("No token found. Verify signature first.");
      return;
    }

    const response = await fetch(`${origin}/set_wallet`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ publicKey }),
    });

    const data = await response.json();

    if (response.ok) {
      console.log("Wallet updated successfully:", data);
      return true;
    } else {
      console.error("Failed to set wallet:", data.error);
      return false;
    }
  } catch (err) {
    console.error("Error accessing set_wallet endpoint:", err);
    return false;
  }
};

const showPrivateKey = async (setWalletPrivateKey) => {
  try {
    const token = localStorage.getItem("authToken");

    if (!token) {
      console.error("No token found. Verify signature first.");
      return;
    }

    const response = await fetch(`${origin}/private_key`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const data = await response.json();

    if (response.ok) {
      console.log("Wallet updated successfully:", data);

      setWalletPrivateKey(data.privateKey);
      return true;
    } else {
      console.error("Failed to set wallet:", data.error);
      return false;
    }
  } catch (err) {
    console.error("Error accessing set_wallet endpoint:", err);
    return false;
  }
};

const deposit = async (
  fromPubkey,
  toPubkey,
  solAmount,
  sendTransaction,
  connection
) => {
  try {
    console.log(
      "called",
      fromPubkey,
      toPubkey,
      solAmount,
      sendTransaction,
      connection
    );

    const transaction = new Transaction().add(
      SystemProgram.transfer({
        fromPubkey,
        toPubkey, // Send to user's Solana keypair
        lamports: solAmount * LAMPORTS_PER_SOL,
      })
    );

    console.log("tx", transaction, connection);

    const signature = await sendTransaction(transaction, connection.connection);
    console.log("Deposit transaction sent, signature:", signature);
  } catch (err) {
    console.error("Error processing deposit:", err);
  }
};

const WithdrawModal = ({ closeModal, initialState }) => {
  const { publicKey, sendTransaction } = useWallet();
  const { setUser, user } = useContext(AppContext);
  const connection = useConnection();

  const [tab, setTab] = useState(initialState);
  const [wallets, setWallets] = useState([]);
  const [selectedWallet, setSelectedWallet] = useState("");
  const [privateKey, setPrivateKey] = useState("");
  const [solAmount, setSolAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [walletPrivateKey, setWalletPrivateKey] = useState("");
  const [toasts, setToasts] = useState([]);

  const renderToast = (toast) => {
    return (
      <Toast
        key={toast.id}
        title={toast.title}
        information={toast.information}
        icon={toast.icon}
        link={toast.link}
      />
    );
  };

  const renderToasts = () => {
    return <div style={{ zIndex: 1000009 }}>{toasts.map(renderToast)}</div>;
  };

  useEffect(() => {
    if (tab === "setWallet" && user?.address) {
      fetchWallets(user.address).then(setWallets);
    }
  }, [tab, user?.address]);

  const fetchUserAndSet = async (setUser, address) => {
    try {
      // Retrieve the token from local storage
      const token = localStorage.getItem("authToken");

      if (!token) {
        console.error("No token found. Please verify your signature first.");
        return;
      }

      // Make a request to the /user endpoint
      const response = await fetch(`${origin}/user`, {
        method: "GET", // Typically, GET is used for user retrieval
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();

      if (response.ok) {
        console.log("User data retrieved successfully:", data);
        // Call setUser with the user data
        console.log("DATA", data);
        if (data.user === null) {
          localStorage.removeItem(address);
          localStorage.removeItem("authToken");
        }
        setUser(data.user || {});
      } else {
        localStorage.removeItem(address);
        localStorage.removeItem("authToken");
        console.error("Failed to retrieve user data:", data.error);
      }
    } catch (err) {
      console.error("Error accessing /user endpoint:", err);
    }
  };

  const recipient =
    tab === "withdraw" ? user.address : user.solanaKeypair.publicKey;

  const handleAction = async () => {
    if (!solAmount) {
      return;
    }

    setLoading(true);
    if (tab === "withdraw") {
      await withdraw(recipient, parseFloat(solAmount));
    } else {
      await deposit(
        publicKey,
        new PublicKey(user.solanaKeypair.publicKey),
        parseFloat(solAmount),
        sendTransaction,
        connection
      );
    }
    setLoading(false);
    closeModal();
  };

  const handleSetWallet = async () => {
    if (!selectedWallet) {
      return;
    }

    const success = await setWallet(selectedWallet);
    fetchUserAndSet(setUser, selectedWallet);
    if (success) {
      setToasts((t) => [
        ...t,
        {
          title: "Wallet Set",
          information: `You have set your active wallet`,
          icon: "check",
          id: toastId++,
        },
      ]);
      // closeModal();
    }
  };

  const handleImportWallet = async () => {
    if (!privateKey) {
      return;
    }

    setLoading(true);
    await importWallet(privateKey);
    setToasts((t) => [
      ...t,
      {
        title: "Wallet Imported",
        information: `You have imported your wallet`,
        icon: "check",
        id: toastId++,
      },
    ]);
    setLoading(false);
    // closeModal();
  };

  return (
    <div
      style={{
        width: "calc(100vw - 40px)",
        height: "calc(100svh - 90px)",
        overflow: "hidden",
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        onClick={closeModal}
        style={{
          position: "absolute",
          width: "calc(100vw - 40px)",
          height: "calc(100svh - 80px)",
          zIndex: 20,
          backdropFilter: "blur(5px)",
        }}
      />
      {renderToasts()}
      <div
        style={{
          background: "rgba(0, 0, 0, 0.35)",
          border: "1px solid rgba(255, 255, 255, 0.1)",
          borderRadius: "10px",
          zIndex: 30,
          minWidth: "600px",
          alignItems: "center",
          padding: "30px 50px",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <div className={css(styles.tabContainer)}>
          <div
            className={css(styles.tab, tab === "withdraw" && styles.activeTab)}
            onClick={() => setTab("withdraw")}
          >
            Withdraw
          </div>
          <div
            className={css(styles.tab, tab === "deposit" && styles.activeTab)}
            onClick={() => setTab("deposit")}
          >
            Deposit
          </div>
          <div
            className={css(styles.tab, tab === "export" && styles.activeTab)}
            onClick={() => setTab("export")}
          >
            Export Private Key
          </div>
          <div
            className={css(
              styles.tab,
              tab === "importWallet" && styles.activeTab
            )}
            onClick={() => setTab("importWallet")}
          >
            Import Wallet
          </div>
          <div
            className={css(styles.tab, tab === "setWallet" && styles.activeTab)}
            onClick={() => setTab("setWallet")}
          >
            Set Wallet
          </div>
        </div>
        {(tab === "withdraw" || tab === "deposit") && (
          <>
            <h2
              style={{ color: "white", textAlign: "center", fontWeight: "600" }}
            >
              {tab === "withdraw" ? "Withdraw SOL" : "Deposit SOL"}
            </h2>
            <input
              type="text"
              placeholder="Recipient Wallet Address"
              value={recipient}
              disabled={tab === "deposit"}
              className={css(styles.input)}
            />
            <input
              type="number"
              placeholder="Amount (SOL)"
              value={solAmount}
              onChange={(e) => setSolAmount(e.target.value)}
              className={css(styles.input)}
            />
            <button
              onClick={handleAction}
              className={css(styles.primaryButton)}
              disabled={loading}
            >
              {loading
                ? "Processing..."
                : tab === "withdraw"
                ? "Withdraw"
                : "Deposit"}
            </button>
          </>
        )}
        {tab === "importWallet" && (
          <>
            <h2
              style={{ color: "white", textAlign: "center", fontWeight: "600" }}
            >
              Import Wallet
            </h2>
            <input
              type="text"
              placeholder="Enter Private Key"
              value={privateKey}
              onChange={(e) => setPrivateKey(e.target.value)}
              className={css(styles.input)}
            />
            <button
              onClick={handleImportWallet}
              className={css(styles.primaryButton)}
              disabled={loading}
            >
              {loading ? "Importing..." : "Import Wallet"}
            </button>
          </>
        )}
        {tab === "export" && (
          <>
            <h2
              style={{ color: "white", textAlign: "center", fontWeight: "600" }}
            >
              Export Private Key
            </h2>
            <div>{walletPrivateKey}</div>
            <button
              onClick={() => showPrivateKey(setWalletPrivateKey)}
              className={css(styles.primaryButton)}
              disabled={loading}
            >
              {"Show Private Key"}
            </button>
          </>
        )}

        {tab === "setWallet" && (
          <>
            <h2
              style={{ color: "white", textAlign: "center", fontWeight: "600" }}
            >
              Set Active Wallet
            </h2>
            <select
              value={selectedWallet}
              onChange={(e) => setSelectedWallet(e.target.value)}
              className={css(styles.input)}
            >
              <option value="">Select a wallet</option>
              {wallets.map((pk, idx) => (
                <option key={idx} value={pk}>
                  {pk}
                </option>
              ))}
            </select>
            <button
              onClick={handleSetWallet}
              className={css(styles.primaryButton)}
            >
              Set Wallet
            </button>
          </>
        )}

        <button onClick={closeModal} className={css(styles.primaryButton)}>
          Close
        </button>
      </div>
    </div>
  );
};

export default WithdrawModal;
