import React, { useContext, useEffect, useState } from "react";
import { css, StyleSheet } from "aphrodite";
import {
  commafy,
  origin,
  formatToThreeSignificantDigits,
  formatToXSignificantDigits,
  normalizeNumber,
} from "./utils";
import AppContext from "./AppContext";
import Toast from "./Toast";
import tokensHeader from "./TokensHeader";
import { ImageWithFallback } from "./ImageWithFallback";
import CandleChart from "./CandleChart";

let toastId = 10000;

const holdingGridTemplateColumns =
  "200px minmax(150px, 200px) repeat(4, minmax(100px, 120px)) minmax(150px, 1fr) 220px";

const styles = StyleSheet.create({
  holdingContainer: {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    overflow: "hidden",
    padding: "15px",
    width: "100%",
    minHeight: "94px",
    alignItems: "center",
    borderRadius: "10px",
    transition: "0.3s all",
    cursor: "pointer",

    display: "grid",
    gridTemplateColumns: holdingGridTemplateColumns,
    ":hover": {
      backgroundColor: "rgba(0, 0, 0, 0.4)",
    },
  },
  holdingContainerMini: {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    overflow: "hidden",
    padding: "15px",
    width: "100%",
    minHeight: "94px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "10px",
    transition: "0.3s all",
    cursor: "pointer",
    ":hover": {
      backgroundColor: "rgba(0, 0, 0, 0.4)",
    },
  },
  sellButton: {
    padding: "5px",
    backgroundColor: "rgba(255, 255, 255, 0.05)",
    border: "1px solid rgba(255, 255, 255, 0.1)",
    display: "inline-flex",
    borderRadius: "20px",
    justifySelf: "flex-end",
    width: "100px",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    position: "relative", // Needed for z-index to work
    zIndex: 10, // Higher value to ensure it sits above other elements
    color: "white", // Ensure text is visible
    fontWeight: "400", // Match text styling
    fontSize: "14px",
    transition: "background-color 0.3s", // Optional: smooth hover effect
    ":hover": {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
    },
  },
});

function formatNumber(value) {
  if (typeof value !== "number") {
    throw new Error("Input must be a number");
  }

  let suffix = "";
  let formattedValue = value;

  if (value >= 1_000_000) {
    suffix = "m";
    formattedValue = value / 1_000_000;
  } else if (value >= 1_000) {
    suffix = "k";
    formattedValue = value / 1_000;
  }

  const roundedValue =
    formattedValue >= 10
      ? Math.round(formattedValue)
      : parseFloat(formattedValue.toFixed(1));

  return `${roundedValue}${suffix}`;
}

function shortenString(str, startLength = 4, endLength = 4) {
  if (str.length <= startLength + endLength) {
    return str; // No truncation needed if the string is too short
  }
  const start = str.slice(0, startLength);
  const end = str.slice(-endLength);
  return `${start}...${end}`;
}

const CircularProgress = ({ percent = 50, size = 80, strokeWidth = 8 }) => {
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (percent / 100) * circumference;

  const strokeColor = percent === 100 ? "green" : `rgba(0, 180, 255, 1)`; // Transition from transparent black to green

  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
      {/* Background Circle */}
      <circle
        cx={size / 2}
        cy={size / 2}
        r={radius}
        fill="none"
        stroke="rgba(0, 0, 0, 0.5)"
        strokeWidth={strokeWidth}
      />
      {/* Progress Circle */}
      <circle
        cx={size / 2}
        cy={size / 2}
        r={radius}
        fill="none"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeDasharray={circumference}
        strokeDashoffset={offset}
        strokeLinecap="round"
        transform={`rotate(-90 ${size / 2} ${size / 2})`} // Rotates so it starts at the top
      />
    </svg>
  );
};

const Holding2 = ({
  isMini,
  holding,
  tokenHolding,
  sellPercentage,
  buyAmount,
}) => {
  const { solPrice, setCoinSelected, setPage, setTokenHoldings, setToasts } =
    useContext(AppContext);

  if (!holding || !tokenHolding) {
    return <></>;
  }

  const { mint, tokenMetadata, marketCap } = holding;

  const {
    name,
    amount,
    symbol,
    decimals,
    isSol,
    tokenAddress,
    image,
    flakeImage,
  } = tokenMetadata;

  tokenHolding.amountRemaining =
    (holding.marketCap / 1_000_000_000) * tokenHolding.quantity;
  tokenHolding.pnl =
    ((tokenHolding.amountRemaining + (tokenHolding.amountSold || 0)) /
      tokenHolding.amountInvested -
      1) *
    100;

  let {
    quantity,
    solAmount,
    amountInvested,
    amountRemaining,
    amountSold,
    pnl,
  } = tokenHolding;

  amountSold = Number(amountSold);

  const finalImage = flakeImage || image;

  const realMarketCap = solPrice * marketCap;

  const sell = async (percentage, mint) => {
    try {
      // Retrieve the token from local storage
      const token = localStorage.getItem("authToken");

      if (!token) {
        console.error("No token found. Verify signature first.");
        return;
      }

      setToasts((t) => [
        ...t,
        {
          title: "Initiating Sell...",
          information: `Sell attempted, please wait`,
          icon: "check",
          id: toastId++,
        },
      ]);

      // Make a request to the protected endpoint
      const response = await fetch(`${origin}/sell`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          percentage,
          mint,
        }),
      });

      const data = await response.json();

      if (data.success) {
        setTokenHoldings((tokenHoldings) => {
          let newTokenHoldings = [];
          let added = false;

          tokenHoldings.forEach((holding) => {
            if (holding.mint === data.tokenHolding.mint) {
              newTokenHoldings.push(data.tokenHolding);
              added = true;
            } else {
              newTokenHoldings.push(holding);
            }
          });

          if (!added) {
            newTokenHoldings.push(data.tokenHolding);
          }

          console.log("allholdings", JSON.stringify(newTokenHoldings, null, 2));

          return newTokenHoldings;
        });
        setToasts((t) => [
          ...t,
          {
            title: "Sell Transaction",
            information: `Signature: ${shortenString(data.signature)}`,
            link: {
              uri: `https://solscan.io/tx/${data.signature}`,
              text: "View On Solscan",
            },
            icon: "check",
            id: toastId++,
          },
        ]);
      } else {
        setToasts((t) => [
          ...t,
          {
            title: "Sell Transaction Failed",
            information: data.message ?? `Something went wrong`,
            icon: "fail",
            id: toastId++,
          },
        ]);
      }

      if (response.ok) {
        console.log("Protected endpoint response:", data);
      } else {
        console.error("Failed to access protected endpoint:", data.error);
      }
    } catch (err) {
      console.error("Error accessing protected endpoint:", err);
    }
  };

  const copy = (mint) => {
    navigator.clipboard
      .writeText(mint)
      .then(() => {
        console.log("Copied to clipboard:", mint);
      })
      .catch((err) => {
        console.error("Failed to copy text:", err);
      });
    setToasts((t) => [
      ...t,
      {
        title: "Copied to clipboard",
        information: `Successfully copied!`,
        icon: "check",
        id: toastId++,
      },
    ]);
  };

  const handleSell = (percentage) => {
    sell(percentage, mint);
  };

  const buy = async (solAmount, mint) => {
    console.log("wtf", localStorage.getItem("authToken"));
    if (!localStorage.getItem("authToken")) {
      setToasts((t) => [
        ...t,
        {
          title: "Not Connected",
          information: `You must connect and fund your wallet`,
          icon: "fail",
          id: toastId++,
        },
      ]);
      return;
    }
    try {
      // Retrieve the token from local storage
      const token = localStorage.getItem("authToken");

      if (!token) {
        console.error("No token found. Verify signature first.");
        return;
      }

      // Make a request to the protected endpoint
      const response = await fetch(`${origin}/buy`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          sol_amount: solAmount,
          mint,
        }),
      });

      const data = await response.json();

      if (data.success) {
        setToasts((t) => [
          ...t,
          {
            title: "Buy Transaction",
            information: `Signature: ${shortenString(data.signature)}`,
            link: {
              uri: `https://solscan.io/tx/${data.signature}`,
              text: "View On Solscan",
            },
            icon: "check",
            id: toastId++,
          },
        ]);
      }

      if (response.ok) {
        console.log("Protected endpoint response:", data);
      } else {
        console.error("Failed to access protected endpoint:", data.error);
      }
    } catch (err) {
      console.error("Error accessing protected endpoint:", err);
    }
  };

  const handleBuy = (amount) => {
    buy(amount, mint);
  };

  const profit =
    Number(amountRemaining) + Number(amountSold || 0) - Number(amountInvested);

  let { hitRaydium, hasMint, remainingTokens } = holding;

  const isMigrating = hitRaydium && !hasMint;

  const shouldShowPumpFun =
    holding.tokenMetadata.createdOn === "https://pump.fun";
  const shouldShowRaydium = holding.hitRaydium === true;

  if (isMini) {
    return (
      <div
        className={css(styles.holdingContainerMini)}
        onClick={(e) => {
          e.preventDefault();
          setCoinSelected(holding);
          setPage("tokenScreen");
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "12px",
            alignItems: "center",
            height: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
              alignItems: "center",
            }}
          >
            <div>
              <ImageWithFallback
                imageUri={finalImage || `${window.location.origin}/missing.png`}
                fallbackUri={`${window.location.origin}/missing.png`}
                name={name}
                size={40}
              />
            </div>
            <div
              style={{
                fontWeight: "700",
                fontSize: "14px",
                textAlign: "center",
                marginTop: "5px",
              }}
            >{`$${formatNumber(Math.floor(Number(realMarketCap)))}`}</div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
              gap: "5px",
              width: "100px",
            }}
          >
            <div>
              <div
                style={{
                  color: "white",
                  fontWeight: "700",
                  fontSize: "16px",
                  marginBottom: "5px",
                }}
              >
                {symbol}
              </div>
              <div
                style={{
                  color: "rgba(255, 255, 255, 0.5)",
                  fontSize: "14px",
                  whiteSpace: "nowrap",
                }}
              >{`${formatNumber(Math.floor(quantity))} ${symbol}`}</div>
            </div>
            <div
              style={{
                color: "white",
                fontWeight: "400",
                overflow: "hidden",
                display: "flex",
                alignItems: "center",
                fontSize: "14px",
              }}
            >
              <img
                src={`${window.location.origin}/sol.png`}
                alt="Solana Logo"
                style={{
                  borderRadius: "50%",
                  width: "17px", // Adjust size as needed
                  height: "17px",
                }}
              />
              {Number(amountRemaining).toFixed(3)}
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            alignItems: "center",
          }}
        >
          <button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleSell(sellPercentage);
            }}
            style={{
              padding: "5px",
              backgroundColor: "rgba(255, 255, 255, 0.05)",
              border: "1px solid rgba(255, 255, 255, 0.1)",
              display: "inline-flex",
              borderRadius: "20px",
              justifySelf: "flex-end",
              width: "100px",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              position: "relative", // Needed for z-index to work
              zIndex: 10, // Higher value to ensure it sits above other elements
              color: "white", // Ensure text is visible
              fontWeight: "700", // Match text styling
              fontSize: "14px",
              transition: "background-color 0.3s", // Optional: smooth hover effect
            }}
            onMouseOver={(e) => {
              e.currentTarget.style.backgroundColor =
                "rgba(255, 255, 255, 0.1)";
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.backgroundColor =
                "rgba(255, 255, 255, 0.05)";
            }}
          >
            🔖
            <span style={{ paddingLeft: "5px" }}>{`Sell ${normalizeNumber(
              sellPercentage
            )}%`}</span>
          </button>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "2px",
            }}
          >
            <div
              style={{
                fontSize: "14px",
                color: Number(pnl) >= 0 ? "#4fff69" : "#FF4D4D",
              }}
            >
              {`${Number(pnl) >= 0 ? "+" : ""}${
                Number.isFinite(Math.round(Number(pnl)))
                  ? Math.round(Number(pnl))
                  : "-"
              }%`}
            </div>
            <div
              style={{
                color: Number(pnl) >= 0 ? "#4fff69" : "#FF4D4D",
                fontWeight: "400",
                overflow: "hidden",
                display: "flex",
                alignItems: "center",
                fontSize: "12px",
              }}
            >
              {profit >= 0 ? "+" : "-"}
              {`$${Math.abs(Number(profit * solPrice)).toFixed(2)}`}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={css(styles.holdingContainer)}
      onClick={(e) => {
        e.preventDefault();
        setCoinSelected(holding);
        setPage("tokenScreen");
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "20px",
            overflow: "hidden",
            boxSizing: "border-box",
          }}
        >
          <div style={{ position: "relative", display: "inline-block" }}>
            <CircularProgress
              percent={
                hitRaydium
                  ? 100
                  : ((1e9 - remainingTokens) / (1e9 - 206900000)) * 100
              }
              size={60}
              strokeWidth={6}
            />
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <ImageWithFallback
                imageUri={
                  holding.tokenMetadata.flakeImage ||
                  holding.tokenMetadata.image ||
                  `${window.location.origin}/missing.png`
                }
                fallbackUri={`${window.location.origin}/missing.png`}
                name={holding.tokenMetadata.name}
                size={50}
              />
            </div>
            {shouldShowPumpFun && (
              <a
                href={`https://pump.fun/coin/${mint}`}
                target="_blank"
                style={{ display: "inline-block" }} // Ensures transform works correctly
              >
                <div
                  style={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: 20,
                    height: 20,
                    backgroundColor: "black",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: 2,
                    transition: "transform 0.2s ease-in-out",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.transform = "scale(1.4)")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.transform = "scale(1)")
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <img
                    src={`${window.location.origin}/pump.webp`}
                    alt="Pump.fun"
                    style={{
                      width: 14,
                      height: 14,
                    }}
                  />
                </div>
              </a>
            )}

            {/* Raydium Logo Overlay (Bottom Right) with Black Circle Background */}
            {shouldShowRaydium && (
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                  width: 20,
                  height: 20,
                  backgroundColor: "black",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  zIndex: 2,
                }}
              >
                <img
                  src={`${window.location.origin}/ray.png`}
                  alt="Raydium"
                  style={{
                    width: 14,
                    height: 14,
                  }}
                />
              </div>
            )}
          </div>
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              width: "150px",
              overflow: "hidden",
              whiteSpace: "pre-wrap",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <div
                style={{
                  color: "white",
                  fontWeight: "400",
                  overflow: "hidden",
                }}
              >
                {`$${holding.tokenMetadata.symbol}`}
              </div>
              <div
                style={{
                  color: "rgb(106, 106, 109)",
                  fontSize: "12px",
                  display: "flex",
                  gap: "5px",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  copy(holding.mint);
                }}
              >
                {shortenString(holding.mint)}
                <button
                  style={{
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    opacity: 0.8, // Slightly transparent
                  }}
                >
                  <img
                    src={`${window.location.origin}/copying.png`}
                    alt="Copy Icon"
                    height={14}
                    style={{
                      filter: "invert(100%)", // Makes the image white
                      opacity: 0.8, // Matches the button transparency
                    }}
                  />
                </button>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  paddingLeft: "4px",
                  alignItems: "center", // Optional: Align items vertically in the center
                }}
              >
                {holding.tokenMetadata.telegram && (
                  <a
                    style={{
                      background: "none",
                      border: "none",
                      padding: 0,
                      cursor: "pointer",
                      display: "inline-block", // Ensures transform works correctly
                    }}
                    href={holding.tokenMetadata.telegram}
                    target="_blank"
                  >
                    <img
                      src={`${window.location.origin}/telegram.png`}
                      alt="Telegram"
                      width={14}
                      height={14}
                      style={{
                        filter: "invert(100%)",
                        opacity: 0.4,
                        transition: "transform 0.2s ease-in-out",
                      }}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.transform = "scale(1.4)")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.transform = "scale(1)")
                      }
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    />
                  </a>
                )}

                {holding.tokenMetadata.twitter && (
                  <a
                    style={{
                      background: "none",
                      border: "none",
                      padding: 0,
                      cursor: "pointer",
                      display: "inline-block",
                    }}
                    href={holding.tokenMetadata.twitter}
                    target="_blank"
                  >
                    <img
                      src={`${window.location.origin}/twitter.png`}
                      alt="Twitter"
                      width={14}
                      height={14}
                      style={{
                        filter: "invert(100%)",
                        opacity: 0.4,
                        transition: "transform 0.2s ease-in-out",
                      }}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.transform = "scale(1.4)")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.transform = "scale(1)")
                      }
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    />
                  </a>
                )}

                {holding.tokenMetadata.website && (
                  <a
                    style={{
                      background: "none",
                      border: "none",
                      padding: 0,
                      cursor: "pointer",
                      display: "inline-block",
                    }}
                    href={holding.tokenMetadata.website}
                    target="_blank"
                  >
                    <img
                      src={`${window.location.origin}/web.png`}
                      alt="Website"
                      width={14}
                      height={14}
                      style={{
                        filter: "invert(100%)",
                        opacity: 0.4,
                        transition: "transform 0.2s ease-in-out",
                      }}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.transform = "scale(1.4)")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.transform = "scale(1)")
                      }
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    />
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          gap: "10px",
          width: "150px",
          overflow: "hidden",
          whiteSpace: "pre-wrap",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <div
            style={{
              color: "white",
              fontWeight: "400",
              overflow: "hidden",
              display: "flex",
              alignItems: "center",
              fontSize: "14px",
            }}
          >
            <img
              src={`${window.location.origin}/sol.png`}
              alt="Solana Logo"
              style={{
                borderRadius: "50%",
                width: "16px", // Adjust size as needed
                height: "16px",
              }}
            />
            {Number(amountInvested).toFixed(3)}
          </div>
          <div style={{ color: "rgba(255, 255, 255, 0.6)", fontSize: "14px" }}>
            {"$" + Number(amountInvested * solPrice).toFixed(2)}
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          gap: "10px",
          width: "150px",
          overflow: "hidden",
          whiteSpace: "pre-wrap",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <div
            style={{
              color: "white",
              fontWeight: "400",
              overflow: "hidden",
              display: "flex",
              alignItems: "center",
              fontSize: "14px",
            }}
          >
            <img
              src={`${window.location.origin}/sol.png`}
              alt="Solana Logo"
              style={{
                borderRadius: "50%",
                width: "16px", // Adjust size as needed
                height: "16px",
              }}
            />
            {Number(amountRemaining).toFixed(3)}
          </div>

          <div style={{ color: "rgba(255, 255, 255, 0.6)", fontSize: "14px" }}>
            {`${formatNumber(Math.floor(quantity))} ${symbol}`}
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          gap: "10px",
          width: "150px",
          overflow: "hidden",
          whiteSpace: "pre-wrap",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <div
            style={{
              color: "white",
              fontWeight: "400",
              overflow: "hidden",
              display: "flex",
              alignItems: "center",
              fontSize: "14px",
            }}
          >
            <img
              src={`${window.location.origin}/sol.png`}
              alt="Solana Logo"
              style={{
                borderRadius: "50%",
                width: "16px", // Adjust size as needed
                height: "16px",
              }}
            />
            {Number(amountSold || 0).toFixed(3)}
          </div>
          <div style={{ color: "rgba(255, 255, 255, 0.6)", fontSize: "14px" }}>
            {"$" + (Number(amountSold || 0) * solPrice).toFixed(2)}
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
          width: "150px",
          overflow: "hidden",
          whiteSpace: "pre-wrap",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <div
            style={{
              color: "white",
              fontWeight: "400",
              overflow: "hidden",
              display: "flex",
              alignItems: "center",
              fontSize: "14px",
            }}
          >
            {`$${formatNumber(Math.floor(Number(realMarketCap)))}`}
          </div>
          <div style={{ color: "rgba(255, 255, 255, 0.6)", fontSize: "14px" }}>
            MC
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          gap: "10px",
          width: "150px",
          overflow: "hidden",
          whiteSpace: "pre-wrap",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <div
            style={{
              color: Number(pnl) >= 0 ? "#4fff69" : "#FF4D4D",
              overflow: "hidden",
              display: "flex",
              fontSize: "14px",
              gap: "5px",
            }}
          >
            <img
              src={`${window.location.origin}/sol.png`}
              alt="Solana Logo"
              style={{
                borderRadius: "50%",
                width: "16px", // Adjust size as needed
                height: "16px",
              }}
            />
            {`${profit >= 0 ? "+" : ""}${profit.toFixed(3)}`}
          </div>

          <div
            style={{
              color: Number(pnl) >= 0 ? "#4fff69" : "#FF4D4D",
              fontSize: "14px",
            }}
          >
            {`${profit >= 0 ? "+$" : "-$"}${Math.abs(profit * solPrice).toFixed(
              2
            )}`}
          </div>
        </div>
      </div>
      <div style={{ maxWidth: "400px" }}>
        <CandleChart bars={[]} marketCap={Math.floor(realMarketCap)} />
      </div>
      <div style={{ display: "flex", gap: "8px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            alignItems: "center",
          }}
        >
          <button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleSell(sellPercentage);
            }}
            className={css(styles.sellButton)}
          >
            🔖
            <span style={{ paddingLeft: "5px" }}>{`Sell ${normalizeNumber(
              sellPercentage
            )}%`}</span>
          </button>

          <button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleSell(20);
            }}
            className={css(styles.sellButton)}
          >
            🏷️
            <span style={{ paddingLeft: "5px" }}>{`Sell ${normalizeNumber(
              20
            )}%`}</span>
          </button>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            alignItems: "center",
          }}
        >
          <button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleSell(50);
            }}
            className={css(styles.sellButton)}
          >
            🏷️
            <span style={{ paddingLeft: "5px" }}>{`Sell ${normalizeNumber(
              50
            )}%`}</span>
          </button>

          <button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleSell(100);
            }}
            className={css(styles.sellButton)}
          >
            🏷️
            <span style={{ paddingLeft: "5px" }}>{`Sell ${normalizeNumber(
              100
            )}%`}</span>
          </button>
        </div>
      </div>
    </div>
    //</a>
  );
};

export default Holding2;
