import React, { useState } from "react";
import { css, StyleSheet } from "aphrodite";
import Toast from "./Toast";
import AppContext from "./AppContext";
import { ImageWithFallback } from "./ImageWithFallback.js";
import {
  commafy,
  formatToThreeSignificantDigits,
  formatToXSignificantDigits,
  origin,
} from "./utils.js";

const styles = StyleSheet.create({
  coinContainer: {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    display: "flex",
    alignItems: "center",
    borderRadius: "10px",
    transition: "0.3s all",
    width: "100%",
    cursor: "pointer",
    ":hover": {
      backgroundColor: "rgba(0, 0, 0, 0.4)",
    },
  },
  userInfoContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: "20px",
    boxSizing: "border-box",
  },
  detailColumn: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    flexShrink: 0,
    textAlign: "left",
  },
  label: {
    color: "rgba(255, 255, 255, 0.6)",
    fontSize: "14px",
  },
  value: {
    color: "white",
    fontWeight: "700",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: "100%",
    display: "flex",
    gap: "5px",
  },
  valueFlex: {
    display: "flex",
    gap: "5px",
    alignItems: "flex-end",
    color: "white",
    fontWeight: "700",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  rankColumn: {
    flex: "0 0 10%", // Fixed width for Rank column
    textAlign: "left",
  },
  addressColumn: {
    flex: "0 0 30%", // Fixed width for Address column
    overflow: "hidden",
  },
  addressColumnMobile: {
    flex: "0 0 30%", // Fixed width for Address column
    overflow: "hidden",
  },
  pointsColumn: {
    flex: "0 0 25%", // Fixed width for Points column
    textAlign: "left",
  },
  shareColumn: {
    flex: "0 0 35%", // Fixed width for Current Share column
    textAlign: "right",
    alignItems: "flex-end",
    overflow: "hidden",
  },
});

let toastId = 1;

function shortenString(str, startLength = 6, endLength = 6) {
  if (str.length <= startLength + endLength) {
    return str; // No truncation needed if the string is too short
  }
  const start = str.slice(0, startLength);
  const end = str.slice(-endLength);
  return `${start}...${end}`;
}

const LeaderboardUser = ({ user, rank, totalPoints, isMobile }) => {
  const [toasts, setToasts] = useState([]);

  const renderToast = (toast) => {
    return (
      <Toast
        key={toast.id}
        title={toast.title}
        information={toast.information}
        icon={toast.icon}
        link={toast.link}
      />
    );
  };

  const renderToasts = () => {
    return <div>{toasts.map(renderToast)}</div>;
  };

  const copy = (mint) => {
    navigator.clipboard
      .writeText(mint)
      .then(() => {
        console.log("Copied to clipboard:", mint);
      })
      .catch((err) => {
        console.error("Failed to copy text:", err);
      });
    setToasts((t) => [
      ...t,
      {
        title: "Copied to clipboard",
        information: `Successfully copied!`,
        icon: "check",
        id: toastId++,
      },
    ]);
  };

  return (
    <div className={css(styles.coinContainer)}>
      <div className={css(styles.userInfoContainer)}>
        {renderToasts()}
        {/* Rank */}
        {!isMobile && (
          <div className={css(styles.detailColumn, styles.rankColumn)}>
            <div className={css(styles.label)}>Rank</div>
            <div className={css(styles.value)}>{rank}</div>
          </div>
        )}

        {/* Address */}
        <div
          className={css(
            styles.detailColumn,
            isMobile ? styles.addressColumnMobile : styles.addressColumn
          )}
        >
          <div className={css(styles.label)}>Address</div>
          <div className={css(styles.value)}>
            {`👑 `}
            {shortenString(
              user.solanaKeypair.publicKey,
              isMobile ? 2 : 6,
              isMobile ? 2 : 6
            )}
            <button
              style={{
                background: "none",
                border: "none",
                cursor: "pointer",
                opacity: 0.8, // Slightly transparent
              }}
              onClick={() => copy(user.solanaKeypair.publicKey)}
            >
              <img
                src={`${window.location.origin}/copying.png`}
                alt="Copy Icon"
                height={14}
                style={{
                  filter: "invert(100%)", // Makes the image white
                  opacity: 0.8, // Matches the button transparency
                }}
              />
            </button>
          </div>
        </div>

        {/* Points */}
        <div className={css(styles.detailColumn, styles.pointsColumn)}>
          <div className={css(styles.label)}>Droplets</div>
          <div style={{ display: "flex", gap: "5px" }}>
            💧
            <div className={css(styles.value)}>
              {commafy(Math.floor(user.points))}
            </div>
          </div>
        </div>

        {/* Current Share */}
        <div className={css(styles.detailColumn, styles.shareColumn)}>
          <div className={css(styles.label)}>Cur Share</div>
          <div className={css(styles.valueFlex)}>
            <img
              src={`${window.location.origin}/flake.png`}
              height={18}
              alt="flake"
            />
            {Math.floor(
              (user.points / (totalPoints || 1)) * 50_000_000
            ).toLocaleString()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaderboardUser;
