import React, { useState, useEffect, useContext } from "react";
import Holdings from "./Holdings";
import oldStyles from "./oldStyles";
import LeaderboardUser from "./LeaderboardUser";
import { StyleSheet, css } from "aphrodite";
import AppContext from "./AppContext";
import { commafy, origin } from "./utils";

const styles = StyleSheet.create({
  coinContainer: {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    display: "flex",
    flexDirection: "column",
    borderRadius: "10px",
    flexBasis: "45%",
    width: "100%",
    padding: "15px",
    boxSizing: "border-box",
  },
  coinContainerMobile: {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    display: "flex",
    flexDirection: "column",
    borderRadius: "10px",
    flexBasis: "100%",
    width: "100%",
    padding: "15px",
    boxSizing: "border-box",
  },
  label: {
    color: "rgba(255, 255, 255, 0.6)",
    fontSize: "14px",
    marginBottom: "10px",
  },
  fullWidth: {
    flexBasis: "100%",
  },
});

function formatNumber(value) {
  if (typeof value !== "number") {
    throw new Error("Input must be a number");
  }

  let suffix = "";
  let formattedValue = value;

  if (value >= 1_000_000) {
    suffix = "m";
    formattedValue = value / 1_000_000;
  } else if (value >= 1_000) {
    suffix = "k";
    formattedValue = value / 1_000;
  }

  const roundedValue =
    formattedValue >= 10
      ? Math.round(formattedValue)
      : parseFloat(formattedValue.toFixed(1));

  return `${roundedValue}${suffix}`;
}

const Leaderboard = () => {
  const [sellPercentage, setSellPercentage] = useState(50);
  const [users, setUsers] = useState([]);
  const [totalPoints, setTotalPoints] = useState(0);
  const [totalHoldings, setTotalHoldings] = useState(0);
  const [multiplier, setMultiplier] = useState(1);
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const { user, isMobile } = useContext(AppContext);

  useEffect(() => {
    const fetchLeaderboard = async () => {
      try {
        // Retrieve the token from local storage
        const token = localStorage.getItem("authToken");

        // Prepare headers, including Authorization if the token exists
        const headers = {
          "Content-Type": "application/json",
          ...(token && { Authorization: `Bearer ${token}` }), // Add Authorization header if token is present
        };

        // Make the request to the leaderboard endpoint
        const response = await fetch(`${origin}/leaderboard`, {
          method: "GET",
          headers: headers,
        });

        const data = await response.json();

        if (response.ok) {
          // Update state with the leaderboard data
          setUsers(data.topUsers || []);
          setTotalPoints(data.totalPoints || 0);
          setTotalHoldings(data.totalHoldings || 0);
          setMultiplier(data.multiplier || 1);
        } else {
          console.error("Failed to fetch leaderboard:", data.error);
        }
      } catch (err) {
        console.error("Failed to fetch leaderboard:", err);
      }
    };

    fetchLeaderboard();
  }, []);

  useEffect(() => {
    const targetDate = new Date("March 15, 2025 00:00:00").getTime();

    const updateCountdown = () => {
      const now = new Date().getTime();
      const difference = targetDate - now;

      if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor(
          (difference % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        setTimeLeft({ days, hours, minutes, seconds });
      } else {
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      }
    };

    const interval = setInterval(updateCountdown, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        gap: "0px",
      }}
    >
      <div
        style={{
          ...oldStyles.box,
          flex: 1,
          flexGrow: 1,
          boxSizing: "border-box",
          minHeight: "400px",
        }}
      >
        {users.length === 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: "20px",
              flexGrow: 1,
            }}
          >
            <div
              style={{
                color: "rgba(255, 255, 255, 0.6)",
                flexGrow: 1,
                width: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.2)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid rgba(255, 255, 255, 0.08)",
                borderRadius: "10px",
              }}
            >
              {`Loading Leaderboard...`}
            </div>
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            overflow: "scroll",
            scrollbarWidth: "none",
            gap: "5px",
          }}
        >
          {users.map((user, idx) => (
            <LeaderboardUser
              isMobile={isMobile}
              key={idx}
              user={user}
              rank={idx + 1}
              totalPoints={totalPoints}
            />
          ))}
        </div>
      </div>

      <div
        style={{
          ...oldStyles.box,
          scrollbarWidth: "none",
          overflow: "scroll",
          width: isMobile ? undefined : "320px",
          margin: isMobile ? "20px 0 0 0" : "0 0 0 20px",
        }}
      >
        <div style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
          <div
            className={css(
              isMobile ? styles.coinContainerMobile : styles.coinContainer,
              styles.fullWidth
            )}
          >
            <div className={css(styles.label)}>Season 1 Ends</div>
            <div
              style={{
                fontWeight: "800",
                display: "flex",
                gap: "5px",
                fontSize: "18px",
              }}
            >
              {`🕒 `}
              {timeLeft.days} : {timeLeft.hours} : {timeLeft.minutes} :
              {timeLeft.seconds}
            </div>
          </div>
          <div
            className={css(
              isMobile ? styles.coinContainerMobile : styles.coinContainer
            )}
          >
            <div className={css(styles.label)}>Your Droplets</div>
            <div style={{ fontWeight: "600", display: "flex", gap: "5px" }}>
              💧
              {commafy(Math.floor(user.points || 0))}
            </div>
          </div>
          <div
            className={css(
              isMobile ? styles.coinContainerMobile : styles.coinContainer
            )}
          >
            <div className={css(styles.label)}>Total Droplets</div>
            <div style={{ fontWeight: "600", display: "flex", gap: "5px" }}>
              💧
              {commafy(Math.floor(totalPoints))}
            </div>
          </div>
          <div
            className={css(
              isMobile ? styles.coinContainerMobile : styles.coinContainer
            )}
          >
            <div className={css(styles.label)}>Referrals</div>
            <div style={{ fontWeight: "600", display: "flex", gap: "5px" }}>
              0
            </div>
          </div>
          <div
            className={css(
              isMobile ? styles.coinContainerMobile : styles.coinContainer
            )}
          >
            <div className={css(styles.label)}>Referral Droplets</div>
            <div style={{ fontWeight: "600", display: "flex", gap: "5px" }}>
              💧 0
            </div>
          </div>

          <div className={css(styles.coinContainer, styles.fullWidth)}>
            <div className={css(styles.label)}>Current Share</div>
            <div
              style={{
                fontWeight: "800",
                display: "flex",
                gap: "5px",
                fontSize: "18px",
              }}
            >
              <img
                src={`${window.location.origin}/flake.png`}
                height={18}
                alt="flake"
              />
              {Math.floor(
                ((user.points || 0) / (totalPoints || 1)) * 50_000_000
              ).toLocaleString()}
            </div>
          </div>
          <div
            className={css(
              isMobile ? styles.coinContainerMobile : styles.coinContainer
            )}
          >
            <div className={css(styles.label)}>Flakes Held</div>
            <div style={{ fontWeight: "600", display: "flex", gap: "5px" }}>
              <img
                src={`${window.location.origin}/flake.png`}
                height={18}
                alt="flake"
              />
              {Math.floor(totalHoldings || 0).toLocaleString()}
            </div>
          </div>
          <div
            className={css(
              isMobile ? styles.coinContainerMobile : styles.coinContainer
            )}
          >
            <div className={css(styles.label)}>Holder Bonus</div>
            <div style={{ fontWeight: "600", display: "flex", gap: "5px" }}>
              {`${multiplier || 0}x`}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Leaderboard;
