import React, { useContext, useState, useEffect } from "react";
import { css, StyleSheet, keyframes } from "aphrodite";
import AppContext from "./AppContext";
import Toast from "./Toast";
import { ImageWithFallback } from "./ImageWithFallback.js";
import {
  formatToThreeSignificantDigits,
  formatToXSignificantDigits,
  normalizeNumber,
  origin,
} from "./utils.js";
import CandleChart from "./CandleChart";
const tokenGridTemplateColumns =
  "200px minmax(150px, 200px) repeat(5, minmax(100px, 120px)) minmax(150px, 300px) minmax(160px, 1fr)";

const styles = StyleSheet.create({
  coinContainer: {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    display: "inline-flex",
    alignItems: "center",
    borderRadius: "10px",
    transition: "0.3s all",
    minWidth: "100%",
    cursor: "pointer",
    ":hover": {
      backgroundColor: "rgba(0, 0, 0, 0.4)",
    },
  },
});

const bars = [
  // {
  //   high: 0.3,
  //   low: 0.2,
  //   start: 0.22,
  //   end: 0.26,
  // },
  // {
  //   high: 0.3,
  //   low: 0.23,
  //   start: 0.28,
  //   end: 0.24,
  // },
  // {
  //   high: 0.32,
  //   low: 0.23,
  //   start: 0.26,
  //   end: 0.28,
  // },
];

let toastId = 1;

function shortenString(str, startLength = 4, endLength = 4) {
  if (str.length <= startLength + endLength) {
    return str; // No truncation needed if the string is too short
  }
  const start = str.slice(0, startLength);
  const end = str.slice(-endLength);
  return `${start}...${end}`;
}
const ProgressBar = ({
  percentage = 55,
  barHeight = "50px",
  barWidth = "14px",
  backgroundColor = "rgba(0, 0, 0, 0.2)", // Updated background
  textColor = "white",
  labelColor = "rgb(153,153,157)",
  fontSize = "14px",
  showWarningThreshold = null,
  showWarningDirection = "above",
  // New prop to receive the correct thresholds
  thresholdConfig = null,
  isLocked,
}) => {
  const [filled, setFilled] = useState(0);
  const [showWarning, setShowWarning] = useState(false);
  const validPercentage = Math.max(0, Math.min(percentage, 100));
  const pct = validPercentage / 100;

  // Helper: compute the base color by interpolating
  // from red (255,0,0) at 0% to blue (0,157,203) at 100%.
  // Helper: compute the base color with a red to orange to blue gradient.
  const computeBaseColor = (pct) => {
    if (pct < 0.5) {
      // Interpolate from red (255,0,0) to orange (255,165,0)
      const newPct = pct / 0.5;
      const r = 255;
      const g = Math.round(0 + (165 - 0) * newPct);
      const b = 0;
      return { r, g, b };
    } else {
      // Interpolate from orange (255,165,0) to blue (0,157,203)
      const newPct = (pct - 0.5) / 0.5;
      const r = Math.round(255 - 255 * newPct);
      const g = Math.round(165 + (157 - 165) * newPct);
      const b = Math.round(0 + 203 * newPct);
      return { r, g, b };
    }
  };

  // Helper: compute the max highlight color,
  // from a red highlight (255,100,100) at 0% to a blue highlight (0,200,255) at 100%.
  // Helper: compute the max highlight color with a similar three-phase interpolation.
  const computeMaxHighlightColor = (pct) => {
    if (pct < 0.5) {
      // Interpolate from a red highlight (255,100,100) to an orange highlight (255,180,100)
      const newPct = pct / 0.5;
      const r = 255;
      const g = Math.round(100 + (180 - 100) * newPct);
      const b = 100;
      return { r, g, b };
    } else {
      // Interpolate from an orange highlight (255,180,100) to a blue highlight (0,200,255)
      const newPct = (pct - 0.5) / 0.5;
      const r = Math.round(255 - 255 * newPct);
      const g = Math.round(180 + (200 - 180) * newPct);
      const b = Math.round(100 + (255 - 100) * newPct);
      return { r, g, b };
    }
  };

  // Blend two colors by a given factor (0..1)
  const blendColors = (color1, color2, factor) => {
    const r = Math.round(color1.r + (color2.r - color1.r) * factor);
    const g = Math.round(color1.g + (color2.g - color1.g) * factor);
    const b = Math.round(color1.b + (color2.b - color1.b) * factor);
    return { r, g, b };
  };

  // Compute the base and maximum highlight colors.
  const baseColorObj = computeBaseColor(pct);
  const maxHighlightColorObj = computeMaxHighlightColor(pct);

  // Set maximum highlight strength to 25%
  const maxHighlightStrength = 0.25;
  // For low fills (<25%), scale the highlight proportionally.
  const effectiveHighlightFactor =
    pct < 0.25 ? (pct / 0.25) * maxHighlightStrength : maxHighlightStrength;

  // Calculate the final highlight color (milder effect).
  const highlightColorObj = blendColors(
    baseColorObj,
    maxHighlightColorObj,
    effectiveHighlightFactor
  );

  // Helper: convert a color object to an rgb() string.
  const colorToString = ({ r, g, b }) => `rgb(${r},${g},${b})`;

  const baseColor = colorToString(baseColorObj);
  const highlightColor = colorToString(highlightColorObj);

  // Create a subtle vertical gradient from the base (bottom) to the highlight (top)
  const fillColor = `linear-gradient(to top, ${baseColor}, ${highlightColor})`;

  // A color scheme for each bucket label:
  const bucketColors = {
    BAD: "#f44336", // Strong red
    SUS: "#ff9800", // Vivid orange
    MID: "#ffc107", // Bright amber
    GOOD: "#8bc34a", // Lively green
    GREAT: "#00b4ff", // Vibrant blue
  };

  const bucketEmojis = {
    BAD: "🚫", // Red circle cancel emoji
    SUS: "⛔", // Warning emoji
    MID: "⚠️", // 1 star
    GOOD: "⭐", // 2 stars
    GREAT: "🌟", // 3 stars
  };

  // Determine the tag and its color based on the "thresholdConfig" buckets
  const getTagInfoOld = (percentage) => {
    if (!thresholdConfig) {
      return { label: "N/A", color: "#888" };
    }
    const { buckets } = thresholdConfig;
    for (const [bucketLabel, [min, max]] of Object.entries(buckets)) {
      if (percentage >= min && percentage <= max) {
        return {
          label: bucketLabel,
          color: bucketColors[bucketLabel] || "#888",
        };
      }
    }
    return { label: "N/A", color: "#888" };
  };

  function getTagInfo(percentage) {
    if (!thresholdConfig) {
      return { label: "N/A", color: "#888" };
    }
    let lowerBound = 0;
    for (const bucket of thresholdConfig) {
      if (
        percentage / 100 >= lowerBound &&
        percentage / 100.01 < bucket.cutoff
      ) {
        return {
          label: bucket.result,
          emoji: bucketEmojis[bucket.result],
          color: bucketColors[bucket.result] || "#888",
        };
      }
      lowerBound = bucket.cutoff; // update lower bound for the next bucket
    }
    return { label: "N/A", color: "#888" };
  }

  const tagInfo = getTagInfo(validPercentage);

  useEffect(() => {
    setFilled(validPercentage);
    if (showWarningThreshold !== null) {
      setShowWarning(
        showWarningDirection === "above"
          ? validPercentage > showWarningThreshold
          : validPercentage < showWarningThreshold
      );
    }
  }, [validPercentage, showWarningThreshold, showWarningDirection]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "10px",
      }}
    >
      {/* Vertical progress bar */}
      <div
        role="progressbar"
        aria-valuenow={validPercentage}
        aria-valuemin="0"
        aria-valuemax="100"
        style={{
          position: "relative",
          width: barWidth,
          height: barHeight,
          backgroundColor: backgroundColor,
          borderRadius: "4px",
          overflow: "hidden",
          cursor: "pointer",
          transition: "background-color 0.3s ease",
        }}
        title={`${validPercentage}%`}
      >
        <div
          style={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            height: `${filled}%`,
            background: fillColor,
            transition: "height 0.5s ease, background 0.5s ease",
          }}
        />
        {/* Uncomment if you need a locked icon overlay
        {isLocked && (
          <div
            style={{
              position: "absolute",
              top: "12px",
              left: "-2px",
              backgroundColor: "rgba(0, 0, 0, 0.8)",
              borderRadius: "50%",
              padding: "4px",
            }}
          >
            🔒
          </div>
        )} */}
      </div>

      {/* Percentage + Warning + Tag */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "5px",
          color: labelColor,
          fontSize: fontSize,
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          {/* Uncomment to show warning icon when needed
          {showWarning && (
            <img
              height={15}
              width={15}
              src={`${window.location.origin}/warning.png`}
              alt="Warning"
            />
          )} */}
          {isLocked && <span>🔒</span>}
          <div style={{ color: textColor, fontWeight: "700" }}>
            {`${validPercentage}%`}
          </div>
        </div>
        {/* Tag Display */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ fontSize: "16px" }}>{tagInfo.emoji}</span>
          <span style={{ fontSize: "10px", marginLeft: "3px" }}>
            {tagInfo.label}
          </span>
        </div>
        {/* <span
          style={{
            padding: "4px 8px",
            borderRadius: "12px",
            fontSize: "12px",
            fontWeight: "400",
            color: "#fff",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            border: `1px solid ${tagInfo.color}`,
            textAlign: "center",
            width: "fit-content",
          }}
        >
          {tagInfo.label}
        </span> */}
      </div>
    </div>
  );
};

const CircularProgress = ({ percent = 50, size = 80, strokeWidth = 8 }) => {
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (percent / 100) * circumference;

  const strokeColor = percent === 100 ? "green" : `rgba(0, 180, 255, 1)`; // Transition from transparent black to green

  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
      {/* Background Circle */}
      <circle
        cx={size / 2}
        cy={size / 2}
        r={radius}
        fill="none"
        stroke="rgba(0, 0, 0, 0.5)"
        strokeWidth={strokeWidth}
      />
      {/* Progress Circle */}
      <circle
        cx={size / 2}
        cy={size / 2}
        r={radius}
        fill="none"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeDasharray={circumference}
        strokeDashoffset={offset}
        strokeLinecap="round"
        transform={`rotate(-90 ${size / 2} ${size / 2})`} // Rotates so it starts at the top
      />
    </svg>
  );
};

const TraderCoin = ({ coin, sol }) => {
  const [showRewardInfo, setShowRewardInfo] = useState(false);
  const {
    isMobile,
    trades,
    setPage,
    setCoinSelected,
    balance,
    setShowModal,
    solPrice,
    setTokenHoldings,
    setToasts,
  } = useContext(AppContext);

  let {
    tokenMetadata,
    mint,
    createdAt,
    updatedAt,
    hitRaydium,
    hasMint,
    walletAddressCount,
    allTimeHigh,
    insidersProcessed,
    marketCap,
    tokensAccountedFor,
    remainingTokens,
    smartMoney,
    volume,
    insider,
    newWallets,
    scam,
    hasHit,
    whale,
    bot,

    successChance,
  } = coin;

  const { name, symbol, description, image, flakeImage } = tokenMetadata;
  const created_at = createdAt;
  const token_address = mint;

  tokensAccountedFor ||= 1;

  const botPercentage = Math.round(
    ((bot * 10) / tokensAccountedFor) * 10
  ).toFixed(1);
  const insiderPercentage = (
    (Math.round(insider * 10) * 10) /
    tokensAccountedFor
  ).toFixed(1);
  const newWalletsPercentage = (
    (Math.round(newWallets * 10) * 10) /
    tokensAccountedFor
  ).toFixed(1);
  const scamPercentage = (
    (Math.round(scam * 10) * 10) /
    tokensAccountedFor
  ).toFixed(1);
  const smartMoneyPercentage = (
    (Math.round(smartMoney * 10) * 10) /
    tokensAccountedFor
  ).toFixed(1);
  const hasHitPercentage = (
    100 -
    (Math.round(hasHit * 10) * 10) / tokensAccountedFor
  ).toFixed(1);
  const whalePercentage = (
    (Math.round(whale * 10) * 10) /
    tokensAccountedFor
  ).toFixed(1);
  const successPercentage = (successChance * 100).toFixed(1);

  const marketCapReal = Math.floor((marketCap * solPrice) / 100) / 10;

  const volumeReal = Math.floor(volume);

  // successChance

  let newThresholds = {
    insiderPercentage: [
      { cutoff: 0.001, result: "SUS" },
      { cutoff: 0.01, result: "MID" },
      { cutoff: 0.05, result: "GOOD" },
      { cutoff: 0.2, result: "GREAT" },
      { cutoff: 0.3, result: "GREAT" },
      { cutoff: 0.4, result: "GREAT" },
      { cutoff: 0.5, result: "GREAT" },
      { cutoff: 0.6, result: "BAD" },
      { cutoff: 0.7, result: "BAD" },
      { cutoff: 0.8, result: "BAD" },
      { cutoff: 0.9, result: "BAD" },
      { cutoff: 1.1, result: "BAD" },
    ],
    newWalletsPercentage: [
      { cutoff: 0.01, result: "SUS" },
      { cutoff: 0.1, result: "MID" },
      { cutoff: 0.2, result: "GREAT" },
      { cutoff: 0.3, result: "GOOD" },
      { cutoff: 0.4, result: "GOOD" },
      { cutoff: 0.5, result: "GOOD" },
      { cutoff: 0.6, result: "GREAT" },
      { cutoff: 0.7, result: "GREAT" },
      { cutoff: 0.8, result: "MID" },
      { cutoff: 0.9, result: "MID" },
      { cutoff: 1.1, result: "BAD" },
    ],
    botPercentage: [
      { cutoff: 0.01, result: "BAD" },
      { cutoff: 0.1, result: "BAD" },
      { cutoff: 0.2, result: "GOOD" },
      { cutoff: 0.3, result: "GREAT" },
      { cutoff: 0.4, result: "GREAT" },
      { cutoff: 0.5, result: "GOOD" },
      { cutoff: 0.6, result: "GOOD" },
      { cutoff: 0.7, result: "GOOD" },
      { cutoff: 0.8, result: "SUS" },
      { cutoff: 0.9, result: "BAD" },
      { cutoff: 1.1, result: "BAD" },
    ],
    hasHitPercentage: [
      { cutoff: 0.01, result: "BAD" },
      { cutoff: 0.1, result: "BAD" },
      { cutoff: 0.2, result: "GREAT" },
      { cutoff: 0.3, result: "GREAT" },
      { cutoff: 0.4, result: "GOOD" },
      { cutoff: 0.5, result: "GREAT" },
      { cutoff: 0.6, result: "GREAT" },
      { cutoff: 0.7, result: "GREAT" },
      { cutoff: 0.8, result: "BAD" },
      { cutoff: 0.9, result: "BAD" },
      { cutoff: 1.1, result: "BAD" },
    ],
    scamPercentage: [
      { cutoff: 0.01, result: "BAD" },
      { cutoff: 0.1, result: "BAD" },
      { cutoff: 0.2, result: "SUS" },
      { cutoff: 0.3, result: "GOOD" },
      { cutoff: 0.4, result: "GREAT" },
      { cutoff: 0.5, result: "GREAT" },
      { cutoff: 0.6, result: "GREAT" },
      { cutoff: 0.7, result: "GREAT" },
      { cutoff: 0.8, result: "SUS" },
      { cutoff: 0.9, result: "MID" },
      { cutoff: 1.1, result: "BAD" },
    ],
    whalePercentage: [
      { cutoff: 0.01, result: "BAD" },
      { cutoff: 0.1, result: "MID" },
      { cutoff: 0.2, result: "GREAT" },
      { cutoff: 0.3, result: "GOOD" },
      { cutoff: 0.4, result: "GREAT" },
      { cutoff: 0.5, result: "GREAT" },
      { cutoff: 0.6, result: "GREAT" },
      { cutoff: 0.7, result: "MID" },
      { cutoff: 0.8, result: "GOOD" },
      { cutoff: 0.9, result: "BAD" },
      { cutoff: 1.1, result: "BAD" },
    ],
    smartMoneyPercentage: [
      { cutoff: 0.01, result: "SUS" },
      { cutoff: 0.05, result: "GOOD" },
      { cutoff: 1.1, result: "GREAT" },
    ],
    successChance: [
      { cutoff: 0.01, result: "BAD" },
      { cutoff: 0.1, result: "BAD" },
      { cutoff: 0.2, result: "SUS" },
      { cutoff: 0.3, result: "SUS" },
      { cutoff: 0.4, result: "GOOD" },
      { cutoff: 0.5, result: "GOOD" },
      { cutoff: 0.6, result: "GOOD" },
      { cutoff: 0.7, result: "GREAT" },
      { cutoff: 0.8, result: "GREAT" },
      { cutoff: 0.9, result: "GREAT" },
      { cutoff: 1.1, result: "GREAT" },
    ],
  };

  let thresholds = {
    bot: {
      improveDirection: "lower",
      buckets: {
        BAD: [90, 100], // All bots can be a bad sign, but a large amount is fine
        SUS: [80, 90],
        MID: [70, 80],
        GOOD: [60, 70],
        GREAT: [0, 60],
      },
    },
    insider: {
      improveDirection: "higher",
      buckets: {
        BAD: [0, 1],
        SUS: [1, 2],
        MID: [2, 5],
        GOOD: [5, 10],
        GREAT: [10, 100],
      },
    },
    smartMoney: {
      improveDirection: "higher",
      buckets: {
        BAD: [0, 1],
        SUS: [1, 2],
        MID: [2, 5],
        GOOD: [5, 10],
        GREAT: [10, 100],
      },
    },
    newWallets: {
      improveDirection: "lower",
      buckets: {
        BAD: [90, 100],
        SUS: [70, 90],
        MID: [50, 70],
        GOOD: [20, 50],
        GREAT: [0, 20],
      },
    },
    scam: {
      improveDirection: "lower",
      buckets: {
        BAD: [90, 100],
        SUS: [80, 90],
        MID: [70, 80],
        GOOD: [50, 70],
        GREAT: [0, 50],
      },
    },
    whale: {
      improveDirection: "higher",
      buckets: {
        BAD: [0, 10],
        SUS: [10, 20],
        MID: [20, 30],
        GOOD: [30, 50],
        GREAT: [50, 100],
      },
    },
    successChance: {
      improveDirection: "higher",
      buckets: {
        BAD: [0, 20],
        SUS: [20, 40],
        MID: [40, 60],
        GOOD: [60, 80],
        GREAT: [80, 100],
      },
    },
    buyRating: {
      improveDirection: "higher",
      buckets: {
        "SCAM LIKELY": [0, 20],
        CONSIDER: [20, 90],
        "POTENTIAL BUY": [90, 100],
      },
    },
  };

  // Helper function: Given a threshold config array and a percentage, return the corresponding result.
  function getResultForPercentage(thresholdConfig, percentage) {
    // Assuming thresholdConfig is sorted by cutoff in ascending order.
    // Find the first threshold where the percentage is less than or equal to the cutoff.
    for (const threshold of thresholdConfig) {
      if (percentage / 100.01 <= threshold.cutoff) {
        return threshold.result;
      }
    }
    // Fallback if none found (should not happen if thresholds cover 0-1)
    return thresholdConfig[thresholdConfig.length - 1].result;
  }

  // Main function: Counts how many metrics return "BAD"
  function countBadMetrics(
    newThresholds,
    insiderPercentage,
    newWalletsPercentage,
    scamPercentage,
    whalePercentage
  ) {
    let badCount = 0;

    // Get result for each metric using its corresponding threshold config.
    if (
      getResultForPercentage(
        newThresholds.insiderPercentage,
        insiderPercentage
      ) === "BAD"
    ) {
      badCount++;
    }
    if (
      getResultForPercentage(
        newThresholds.newWalletsPercentage,
        newWalletsPercentage
      ) === "BAD"
    ) {
      badCount++;
    }

    // Assuming scam uses threshold config named "hasHitPercentage" within newThresholds.
    if (
      getResultForPercentage(newThresholds.scamPercentage, scamPercentage) ===
      "BAD"
    ) {
      badCount++;
    }

    if (
      getResultForPercentage(newThresholds.whalePercentage, whalePercentage) ===
      "BAD"
    ) {
      badCount++;
    }

    return badCount;
  }

  const buyEmojis = {
    "SCAM LIKELY": "🚫",
    "POSSIBLE SCAM": "🚨",
    "POTENTIAL BUY": "🚀", // 3 stars
  };

  function getBuyRating(percentage) {
    if (percentage < 0.1) {
      return "SCAM LIKELY";
    }

    if (
      countBadMetrics(
        newThresholds,
        insiderPercentage,
        newWalletsPercentage,
        scamPercentage,
        whalePercentage
      ) >= 1
    ) {
      return "POSSIBLE SCAM";
    }

    if (insiderPercentage >= 5) {
      return "POTENTIAL BUY";
    } else {
      return "CONSIDER";
    }
    // const { buckets } = thresholds.buyRating;
    // // Iterate through each named bucket
    // for (const rating in buckets) {
    //   const [min, max] = buckets[rating];
    //   // Check if successChanc falls within the [min, max) range
    //   if (successChanc >= min && successChanc < max) {
    //     return rating;
    //   }
    // }
    // return null; // or "Out of range"
  }

  // Initialize state for percentages and success chance
  // const [insiderPerc, setInsiderPerc] = useState(Math.round(insiderPercentage));
  // const [newWalletPerc, setNewWalletPerc] = useState(
  //   Math.round(newWalletsPercentage)
  // );
  // const [scamPerc, setScamPerc] = useState(Math.round(scamPercentage));
  // const [whalePerc, setWhalePerc] = useState(Math.round(whalePercentage));
  // const [botPerc, setBotPerc] = useState(Math.round(botPercentage));
  // const [successChanc, setSuccessChance] = useState(
  //   Math.round(successPercentage)
  // );

  const formatVolume = (num) => {
    if (num >= 1_000_000) {
      return `${(num / 1_000_000).toFixed(1)}m`; // Convert to millions
    } else if (num >= 1_000) {
      return `${(num / 1_000).toFixed(1)}k`; // Convert to thousands
    } else {
      return num.toString(); // Return as a string if smaller than 1,000
    }
  };

  const formattedVolume = formatVolume(volumeReal);

  const priceOfSol =
    Number(
      trades["So11111111111111111111111111111111111111112"]?.[0]
        ?.price_of_token_in_usd
    ) || 0;
  const priceOfTokenInSol =
    Number(trades[token_address]?.[0]?.price_of_token_in_sol) || 0;

  // Function to calculate age
  const calculateAge = (createdAt) => {
    const now = new Date();
    const createdDate = new Date(createdAt);
    const diffInMs = now - createdDate;

    const seconds = Math.floor(diffInMs / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    if (years > 0) return `${years}y ${months % 12}m`;
    if (months > 0) return `${months}m ${days % 30}d`;
    if (days > 0) return `${days}d ${hours % 24}h`;
    if (hours > 0) return `${hours}h ${minutes % 60}m`;
    if (minutes > 0) return `${minutes}m ${seconds % 60}s`;
    return `${seconds}s`;
  };

  // Initialize age state
  const [age, setAge] = useState(calculateAge(coin.createdAt));

  // Set up effect to update age
  useEffect(() => {
    // Update age immediately in case created_at changes
    setAge(calculateAge(coin.createdAt));

    // Define the interval (e.g., every second)
    const intervalId = setInterval(() => {
      setAge(calculateAge(coin.createdAt));
    }, 1000); // Update every second

    // Clean up the interval on component unmount or when created_at changes
    return () => clearInterval(intervalId);
  }, [created_at]);

  const handleBuy = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (isMigrating) {
      return;
    }
    if (balance / 1_000_000_000 >= sol) {
      buy(sol, coin.mint);
    } else {
      setToasts((t) => [
        ...t,
        {
          title: "Deposit Required",
          information: `You must fund your wallet before buying`,
          icon: "fail",
          id: toastId++,
        },
      ]);
      setShowModal("deposit");
    }
  };

  const buy = async (solAmount, mint) => {
    console.log("wtf", localStorage.getItem("authToken"));
    if (!localStorage.getItem("authToken")) {
      setToasts((t) => [
        ...t,
        {
          title: "Not Connected",
          information: `You must connect and fund your wallet`,
          icon: "fail",
          id: toastId++,
        },
      ]);
      return;
    }
    try {
      // Retrieve the token from local storage
      const token = localStorage.getItem("authToken");

      if (!token) {
        console.error("No token found. Verify signature first.");
        return;
      }

      setToasts((t) => [
        ...t,
        {
          title: "Buying...",
          information: `Transaction in progress..`,
          icon: "check",
          id: toastId++,
        },
      ]);

      // Make a request to the protected endpoint
      const response = await fetch(`${origin}/buy`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          sol_amount: solAmount,
          mint,
        }),
      });

      const data = await response.json();

      if (data.success) {
        setTokenHoldings((tokenHoldings) => {
          let newTokenHoldings = [];
          let added = false;

          tokenHoldings.forEach((holding) => {
            if (holding.mint === data.tokenHolding.mint) {
              newTokenHoldings.push(data.tokenHolding);
              added = true;
            } else {
              newTokenHoldings.push(holding);
            }
          });

          if (!added) {
            newTokenHoldings.push(data.tokenHolding);
          }

          console.log("allholdings", JSON.stringify(newTokenHoldings, null, 2));

          return newTokenHoldings;
        });
        if (data.signature && !data.signature.error) {
          setToasts((t) => [
            ...t,
            {
              title: "Buy Transaction",
              information: `Signature: ${shortenString(data?.signature)}`,
              link: {
                uri: `https://solscan.io/tx/${data?.signature}`,
                text: "View On Solscan",
              },
              icon: "check",
              id: toastId++,
            },
          ]);
        } else {
          setToasts((t) => [
            ...t,
            {
              title: "Error",
              information: `Something went wrong`,
              icon: "fail",
              id: toastId++,
            },
          ]);
        }
      } else {
        setToasts((t) => [
          ...t,
          {
            title: "Buy Transaction Failed",
            information: data.message ?? `Something went wrong`,
            icon: "fail",
            id: toastId++,
          },
        ]);
      }

      if (response.ok) {
        console.log("Protected endpoint response:", data);
      } else {
        console.error("Failed to access protected endpoint:", data.error);
      }
    } catch (err) {
      console.error("Error accessing protected endpoint:", err);
    }
  };

  const copy = (mint) => {
    navigator.clipboard
      .writeText(mint)
      .then(() => {
        console.log("Copied to clipboard:", mint);
      })
      .catch((err) => {
        console.error("Failed to copy text:", err);
      });
    setToasts((t) => [
      ...t,
      {
        title: "Copied to clipboard",
        information: `Successfully copied!`,
        icon: "check",
        id: toastId++,
      },
    ]);
  };

  const tags = [
    "SCAM LIKELY",
    "POSSIBLE SCAM",
    "UNKNOWN",
    "WEAK SIGNAL",
    "POTENTIAL BUY",
    "STRONG BUY",
  ];

  const tagColors = {
    "SCAM LIKELY": "#FF4D4D", // Deep Red
    "POSSIBLE SCAM": "#FF914D", // Burnt Orange
    "MIXED SIGNALS": "#6C757D", // Cool Gray
    "POTENTIAL BUY": "#4DA6FF", // Light Blue
    CONSIDER: "#28C76F", // Vibrant Green
  };

  // BAD: "#f44336", // Strong red
  // SUS: "#ff9800", // Vivid orange
  // MID: "#ffc107", // Bright amber
  // GOOD: "#8bc34a", // Lively green
  // GREAT: "#00b4ff", // Vibrant blue

  const isMigrating = hitRaydium && !hasMint;

  const randomTag = getBuyRating(successPercentage);

  const shouldShowPumpFun = coin.tokenMetadata.createdOn === "https://pump.fun";
  const shouldShowRaydium = coin.hitRaydium === true;

  return (
    // <a href={`https://pump.fun/coin/${mint}`} target="_blank">
    <div>
      <div
        onClick={() => {
          setCoinSelected(coin);
          setPage("tokenScreen");
        }}
        className={css(styles.coinContainer)}
      >
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <div
            style={{
              display: "grid",
              gridTemplateColumns: tokenGridTemplateColumns,
              padding: "10px",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                overflow: "hidden",
                boxSizing: "border-box",
              }}
            >
              <div style={{ position: "relative", display: "inline-block" }}>
                <CircularProgress
                  percent={
                    hitRaydium
                      ? 100
                      : ((1e9 - remainingTokens) / (1e9 - 206900000)) * 100
                  }
                  size={60}
                  strokeWidth={6}
                />
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <ImageWithFallback
                    imageUri={
                      coin.tokenMetadata.flakeImage ||
                      coin.tokenMetadata.image ||
                      `${window.location.origin}/missing.png`
                    }
                    fallbackUri={`${window.location.origin}/missing.png`}
                    name={coin.tokenMetadata.name}
                    size={50}
                  />
                </div>
                {shouldShowPumpFun && (
                  <a
                    href={`https://pump.fun/coin/${mint}`}
                    target="_blank"
                    style={{ display: "inline-block" }} // Ensures transform works correctly
                  >
                    <div
                      style={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        width: 20,
                        height: 20,
                        backgroundColor: "black",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        zIndex: 2,
                        transition: "transform 0.2s ease-in-out",
                      }}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.transform = "scale(1.4)")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.transform = "scale(1)")
                      }
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <img
                        src={`${window.location.origin}/pump.webp`}
                        alt="Pump.fun"
                        style={{
                          width: 14,
                          height: 14,
                        }}
                      />
                    </div>
                  </a>
                )}

                {/* Raydium Logo Overlay (Bottom Right) with Black Circle Background */}
                {shouldShowRaydium && (
                  <div
                    style={{
                      position: "absolute",
                      bottom: 0,
                      right: 0,
                      width: 20,
                      height: 20,
                      backgroundColor: "black",
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      zIndex: 2,
                    }}
                  >
                    <img
                      src={`${window.location.origin}/ray.png`}
                      alt="Raydium"
                      style={{
                        width: 14,
                        height: 14,
                      }}
                    />
                  </div>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  width: "150px",
                  overflow: "hidden",
                  whiteSpace: "pre-wrap",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <div
                    style={{
                      color: "white",
                      fontWeight: "700",
                      overflow: "hidden",
                    }}
                  >
                    {`$${coin.tokenMetadata.symbol}`}
                  </div>
                  <div
                    style={{
                      color: "rgb(106, 106, 109)",
                      fontSize: "12px",
                      display: "flex",
                      gap: "5px",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      copy(coin.mint);
                    }}
                  >
                    {shortenString(coin.mint)}
                    <button
                      style={{
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                        opacity: 0.8, // Slightly transparent
                      }}
                    >
                      <img
                        src={`${window.location.origin}/copying.png`}
                        alt="Copy Icon"
                        height={14}
                        style={{
                          filter: "invert(100%)", // Makes the image white
                          opacity: 0.8, // Matches the button transparency
                        }}
                      />
                    </button>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      paddingLeft: "4px",
                      alignItems: "center", // Optional: Align items vertically in the center
                    }}
                  >
                    {coin.tokenMetadata.telegram && (
                      <a
                        style={{
                          background: "none",
                          border: "none",
                          padding: 0,
                          cursor: "pointer",
                          display: "inline-block", // Ensures transform works correctly
                        }}
                        href={coin.tokenMetadata.telegram}
                        target="_blank"
                      >
                        <img
                          src={`${window.location.origin}/telegram.png`}
                          alt="Telegram"
                          width={14}
                          height={14}
                          style={{
                            filter: "invert(100%)",
                            opacity: 0.4,
                            transition: "transform 0.2s ease-in-out",
                          }}
                          onMouseEnter={(e) =>
                            (e.currentTarget.style.transform = "scale(1.4)")
                          }
                          onMouseLeave={(e) =>
                            (e.currentTarget.style.transform = "scale(1)")
                          }
                        />
                      </a>
                    )}

                    {coin.tokenMetadata.twitter && (
                      <a
                        style={{
                          background: "none",
                          border: "none",
                          padding: 0,
                          cursor: "pointer",
                          display: "inline-block",
                        }}
                        href={coin.tokenMetadata.twitter}
                        target="_blank"
                      >
                        <img
                          src={`${window.location.origin}/twitter.png`}
                          alt="Twitter"
                          width={14}
                          height={14}
                          style={{
                            filter: "invert(100%)",
                            opacity: 0.4,
                            transition: "transform 0.2s ease-in-out",
                          }}
                          onMouseEnter={(e) =>
                            (e.currentTarget.style.transform = "scale(1.4)")
                          }
                          onMouseLeave={(e) =>
                            (e.currentTarget.style.transform = "scale(1)")
                          }
                        />
                      </a>
                    )}

                    {coin.tokenMetadata.website && (
                      <a
                        style={{
                          background: "none",
                          border: "none",
                          padding: 0,
                          cursor: "pointer",
                          display: "inline-block",
                        }}
                        href={coin.tokenMetadata.website}
                        target="_blank"
                      >
                        <img
                          src={`${window.location.origin}/web.png`}
                          alt="Website"
                          width={14}
                          height={14}
                          style={{
                            filter: "invert(100%)",
                            opacity: 0.4,
                            transition: "transform 0.2s ease-in-out",
                          }}
                          onMouseEnter={(e) =>
                            (e.currentTarget.style.transform = "scale(1.4)")
                          }
                          onMouseLeave={(e) =>
                            (e.currentTarget.style.transform = "scale(1)")
                          }
                        />
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "grid",
                /* First column is 60px wide, second column auto-expands */
                gridTemplateColumns: "40px auto",
                gap: "8px",
                width: 150,
                fontSize: 14,
                overflow: "hidden",
                whiteSpace: "pre-wrap",
              }}
            >
              {/* Row 1: Age */}
              <div
                style={{
                  textAlign: "right",
                  color: "rgba(255, 255, 255, 0.6)",
                  fontSize: 12,
                }}
              >
                Age
              </div>
              <div
                style={{
                  color: "white",
                  overflow: "hidden",
                  paddingLeft: "5px",
                  fontSize: "12px",
                  color: "rgba(255, 255, 255, 0.8)",
                }}
              >
                {age}
              </div>

              {/* Row 2: MCap */}
              <div
                style={{
                  textAlign: "right",
                  color: "rgba(255, 255, 255, 0.6)",
                  fontSize: 14,
                }}
              >
                MCap
              </div>
              <div
                style={{
                  fontWeight: 900,
                  color: "rgba(0, 180, 255, 1)",
                  fontSize: "16px",
                  overflow: "hidden",
                  paddingLeft: "5px",
                }}
              >
                {`$${marketCapReal}k`}
              </div>

              {/* Row 3: Volume */}
              <div
                style={{
                  textAlign: "right",
                  color: "rgba(255, 255, 255, 0.6)",
                  fontSize: 12,
                }}
              >
                Vol
              </div>
              <div
                style={{
                  color: "white",
                  overflow: "hidden",
                  fontSize: "12px",
                  paddingLeft: "5px",
                  color: "rgba(255, 255, 255, 0.8)",
                }}
              >
                {`$${formattedVolume}`}
              </div>
            </div>
            {/* Pass the matching threshold object as a prop named "thresholdConfig" (or any name you prefer) */}
            {tokensAccountedFor > 100_000_000 && (
              <>
                <ProgressBar
                  percentage={insiderPercentage}
                  label="Smart Money"
                  thresholdConfig={newThresholds.insiderPercentage}
                  isLocked={hitRaydium} // 🔒
                />
                <ProgressBar
                  percentage={smartMoneyPercentage}
                  label="Good Wal"
                  showWarningThreshold={10}
                  thresholdConfig={newThresholds.smartMoneyPercentage}
                  isLocked={hitRaydium} // 🔒
                />
                <ProgressBar
                  percentage={newWalletsPercentage}
                  label="New Wallet"
                  thresholdConfig={newThresholds.newWalletsPercentage}
                  isLocked={hitRaydium} // 🔒
                />
                <ProgressBar
                  percentage={whalePercentage}
                  label="Whale"
                  thresholdConfig={newThresholds.whalePercentage}
                  isLocked={hitRaydium} // 🔒
                />
                <ProgressBar
                  percentage={successPercentage}
                  label="Success"
                  showWarningThreshold={20}
                  showWarningDirection="below"
                  thresholdConfig={newThresholds.successChance}
                  isLocked={hitRaydium} // 🔒
                />
              </>
            )}
            {tokensAccountedFor <= 100_000_000 && (
              <>
                <div style={{ color: "rgba(255, 255, 255, 0.6)" }}>
                  Insufficient Holders
                </div>
                <div />
                <div />
                <div />
                <div />
              </>
            )}
            <div>
              <CandleChart
                bars={coin.bars ?? []}
                marketCap={Math.floor(marketCap * solPrice)}
              />
            </div>
            <div
              style={{
                justifySelf: "flex-end",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                gap: "10px",
              }}
            >
              <button
                onClick={handleBuy}
                disabled={isMigrating}
                style={{
                  padding: "5px",
                  backgroundColor: "rgba(255, 255, 255, 0.05)",
                  display: "inline-flex",
                  borderRadius: "20px",
                  width: "100px",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: isMigrating ? "not-allowed" : "pointer",
                  position: "relative", // Needed for z-index to work
                  zIndex: 10, // Higher value to ensure it sits above other elements
                  border: "none", // Remove default button border
                  color: "white", // Ensure text is visible
                  fontWeight: "700", // Match text styling
                  transition: "background-color 0.3s", // Optional: smooth hover effect
                }}
                onMouseOver={(e) => {
                  e.currentTarget.style.backgroundColor =
                    "rgba(255, 255, 255, 0.1)";
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.backgroundColor =
                    "rgba(255, 255, 255, 0.05)";
                }}
              >
                {isMigrating && <div>Migrating...</div>}
                {!isMigrating && (
                  <>
                    <img
                      src={`${window.location.origin}/sol.png`}
                      alt="Solana Logo"
                      style={{
                        borderRadius: "50%",
                        width: "24px", // Adjust size as needed
                        height: "24px",
                        marginRight: "8px", // Space between image and number
                      }}
                    />
                    <span>{normalizeNumber(sol) || 0}</span>
                  </>
                )}
              </button>

              <div
                style={{
                  borderRadius: "12px",
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "8px",
                  justifyContent: "center",
                }}
              >
                <span
                  key={randomTag}
                  style={{
                    padding: "6px 12px",
                    borderRadius: "16px",
                    fontSize: "12px",
                    fontWeight: "900",
                    color: "#FFF",
                    border: "1px solid rgba(255, 255, 255, 0.2)",
                    backgroundColor: tagColors[randomTag],
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  {/* <span style={{ fontSize: "14px" }}>
                    {buyEmojis[randomTag]}
                  </span> */}
                  {randomTag}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // </a>
  );
};

export default TraderCoin;
