import React, { useState } from "react";
import { css, StyleSheet } from "aphrodite";

const ToolTip = ({ description }) => {
  const [hovered, setHovered] = useState(false);

  return (
    <div
      // Inline styles for container
      style={{
        position: "relative",
        display: "inline-block",
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {/* The black circle with the question mark */}
      <div className={css(styles.questionMark)}>?</div>

      {hovered && (
        <div
          // Inline styles for the tooltip
          style={{
            position: "absolute",
            top: "30px",
            right: "0",
            width: "400px",
            backgroundColor: "rgba(0,0,0,0.5)",

            backdropFilter: "blur(5px)",
            border: "1px solid rgba(255,255,255,0.1)",
            color: "#FFFFFF",
            padding: "15px",
            textAlign: "center",
            borderRadius: "15px",
            zIndex: 9999,
            fontSize: "16px",
            fontWeight: "400",
            color: "rgba(255, 255, 255, 1)",
          }}
        >
          {description}
        </div>
      )}
    </div>
  );
};

const styles = StyleSheet.create({
  questionMark: {
    backgroundColor: "#1B1E26",
    border: "1px solid rgba(255, 255, 255, 0.1)",
    borderRadius: "50%",
    marginLeft: "3px",
    width: "14px",
    height: "14px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
    fontWeight: 700,
    fontSize: "10px",
    transition: "0.3s all",
    ":hover": {
      // Subtle hover effect on the circle
      backgroundColor: "#2c2f38",
      cursor: "pointer",
    },
  },
});

export default ToolTip;
