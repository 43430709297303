import React from "react";
import ToolTip from "./Tooltip.js";

const tokenGridTemplateColumns =
  "200px minmax(150px, 200px) repeat(5, minmax(100px, 120px)) minmax(150px, 300px) minmax(160px, 1fr)";

const TraderHeader = ({ isPaused }) => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: tokenGridTemplateColumns,
        scrollbarWidth: "none",
        fontSize: "16px",
        position: "relative",
        padding: "5px 5px",
        color: "rgba(255, 255, 255, 0.8)",
        alignItems: "end", // Align grid items at the bottom
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          overflow: "hidden",
        }}
      >
        📖 Tokens
        {isPaused && (
          <span
            style={{
              paddingLeft: "5px",
              fontSize: "14px",
              fontWeight: "900",
              color: "rgba(0, 180, 255, 1)",
            }}
          >
            ⏸︎ PAUSED
          </span>
        )}
      </div>
      {/* <div
        style={{
          color: "white",
          fontSize: "14px",
          paddingLeft: "10px",
          textAlign: "left",
        }}
      >
        🕒 Age
      </div> */}
      <div
        style={{
          color: "white",
          fontSize: "14px",
          paddingLeft: "30px",
          textAlign: "left",
        }}
      >
        💸 Stats
      </div>
      {/* <div
        style={{
          color: "white",
          fontSize: "14px",
          paddingLeft: "10px",
          textAlign: "left",
        }}
      >
         Volume
      </div> */}
      <div
        style={{
          color: "white",
          fontSize: "12px",
          textAlign: "left",
        }}
      >
        🤑 Smart $
        <ToolTip
          description={
            "Percentage of the token held by users who regularly invest on pump.fun with a decent hit rate for bonded tokens."
          }
        />
      </div>

      <div
        style={{
          color: "white",
          fontSize: "12px",
          textAlign: "left",
        }}
      >
        💼 Good Wal
        <ToolTip
          description={
            "Percentage of tokens held by wallets that occasionally have good investments."
          }
        />
      </div>
      <div
        style={{
          color: "white",
          fontSize: "12px",
          textAlign: "left",
        }}
      >
        🌱 New Wal
        <ToolTip
          description={
            "Percentage of tokens held by wallets that were just created or created very recently usually representing scammers, but sometimes insiders."
          }
        />
      </div>
      <div
        style={{
          color: "white",
          fontSize: "12px",
          textAlign: "left",
        }}
      >
        🐋 Whale
        <ToolTip
          description={"Percentage of tokens held by relatively wealthy users."}
        />
      </div>
      <div
        style={{
          color: "white",
          fontSize: "12px",
          textAlign: "left",
        }}
      >
        ⭐ Quality
        <ToolTip
          description={
            "Our estimated quality of the token based on the pool of holders relative to other tokens."
          }
        />
      </div>
      <div
        style={{
          color: "white",
          fontSize: "12px",
          textAlign: "left",
        }}
      >
        🔍 Chart
        <ToolTip description={"Live chart price."} />
      </div>
      <div
        style={{
          color: "white",
          fontSize: "14px",
          textAlign: "right",
          alignSelf: "flex-end",
        }}
      >
        🎯 Instant Buy
        <ToolTip
          description={
            "Advised action based on metrics with the option to purchase"
          }
        />
      </div>
    </div>
  );
};

export default TraderHeader;
