import React, { useContext, useEffect, useRef, useState } from "react";
import oldStyles from "./oldStyles";
import { css, StyleSheet } from "aphrodite";
import TraderCoin from "./TraderCoin";
import AppContext from "./AppContext";
import TraderHeader from "./TraderHeader";
import Strategy from "./Strategy";
import Filters from "./Filters";
import Toast from "./Toast";
import ReferralWidget from "./ReferralWidget";
import Holdings from "./Holdings";
import MiniHoldings from "./MiniHoldings";

const styles = StyleSheet.create({
  coinContainer: {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    display: "inline-flex",
    alignItems: "center",
    borderRadius: "10px",
    transition: "0.3s all",
    cursor: "pointer",
    ":hover": {
      backgroundColor: "rgba(0, 0, 0, 0.4)",
    },
  },
  navButton: {
    background: "rgb(32 129 226)",
    borderRadius: "20px",
    position: "relative",
    padding: "10px 20px",
    fontWeight: "900",
    textAlign: "center",
    display: "inline-block",
    transition: "0.3s all",
    cursor: "pointer",
    ":hover": {
      backgroundColor: "rgb(69, 145, 222)",
    },
  },
  flakeCount: {
    display: "flex",
    gap: "5px",
    alignItems: "center",
    backgroundColor: "rgba(255, 255, 255, 0.05)",
    border: "1px solid rgba(255,255,255,.05)",
    padding: "8px",
    borderRadius: "10px",
    transition: "0.3s all",
    position: "relative",
    cursor: "pointer",
    ":hover": {
      backgroundColor: "rgba(255,255,255,.1)",
      border: "1px solid rgba(255,255,255,.1)",
    },
  },
  tabContainer: {
    display: "flex",
    gap: "10px",
  },
  tab: {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    borderRadius: "10px",
    padding: "8px 14px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    gap: "5px",
    fontWeight: 700,
    transition: "0.3s all",
    ":hover": {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
    },
  },
  tabSelected: {
    backgroundColor: "rgb(32 129 226)",
  },
});

let toastId = 1;

const Trader = () => {
  const { tokens, isMobile, setExchange, fetchingTokens } =
    useContext(AppContext);

  const [displayedTokens, setDisplayedTokens] = useState(tokens);
  const [hoveredCount, setHoveredCount] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [toasts, setToasts] = useState([]);
  const [selectedTab, setSelectedTab] = useState("Pump.fun");
  const [sol, setSol] = useState(() => {
    const storedSol = localStorage.getItem("sol");
    return storedSol !== null ? parseFloat(storedSol) : 0.1;
  });

  useEffect(() => {
    localStorage.setItem("sol", sol);
  }, [sol]);

  // Keep a ref of the latest tokens so we can "catch up" after unpausing.
  const latestTokensRef = useRef(tokens);

  // 1. Create a ref for the container div (for filters)
  const containerRef = useRef(null);

  useEffect(() => {
    setExchange(selectedTab);
  }, [selectedTab]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setShowFilters(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Update displayed tokens whenever tokens context changes and we are not paused
  useEffect(() => {
    latestTokensRef.current = tokens;

    // Get the current set of mint IDs
    const currentMints = new Set(tokens.map((t) => t.mint));
    const oldMints = new Set(displayedTokens.map((t) => t.mint));

    // Count how many mint IDs are new (not in oldMints)
    let differentCount = 0;
    for (const mint of currentMints) {
      if (!oldMints.has(mint)) {
        differentCount++;
      }
    }

    // Calculate the percentage of different tokens
    const differenceRatio = differentCount / Math.max(tokens.length, 1); // Avoid division by zero
    if (!isPaused || differenceRatio > 0.2) {
      // Fully update tokens: add new, remove missing, and sort by market cap
      setDisplayedTokens(
        tokens.sort((a, b) => (b.marketCap || 0) - (a.marketCap || 0))
      );
    } else {
      // Preserve order while updating only the stats of existing tokens
      setDisplayedTokens((oldTokens) => {
        const tokenMap = new Map(tokens.map((t) => [t.mint, t]));

        return oldTokens.map((oldToken) => {
          const updatedToken = tokenMap.get(oldToken.mint);
          return updatedToken ? { ...oldToken, ...updatedToken } : oldToken;
        });
      });
    }
  }, [tokens, isPaused]);

  const handleMouseEnter = () => {
    setIsPaused(true);
  };

  const handleMouseLeave = () => {
    setIsPaused(false);
  };

  // Toast rendering
  const renderToast = (toast) => {
    return (
      <Toast
        key={toast.id}
        title={toast.title}
        information={toast.information}
        icon={toast.icon}
        link={toast.link}
      />
    );
  };

  const renderToasts = () => {
    return <div>{toasts.map(renderToast)}</div>;
  };

  // Copy function for contract address
  const copy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log("Copied to clipboard:", text);
      })
      .catch((err) => {
        console.error("Failed to copy text:", err);
      });
    setToasts((t) => [
      ...t,
      {
        title: "Copied to clipboard",
        information: `Successfully copied!`,
        icon: "check",
        id: toastId++,
      },
    ]);
  };

  // Array of tab labels
  const tabs = ["Pump.fun", "DEX", "All"];
  const imagePaths = {
    "Pump.fun": "pump.webp",
    DEX: "ray.png",
  };

  return (
    <>
      {renderToasts()}
      <div
        style={{
          ...oldStyles.box,
          scrollbarWidth: "none",
          flexGrow: 1,
          width: isMobile ? "100%" : "500px",
          minWidth: isMobile ? undefined : "600px",
          flexDirection: "column",
        }}
      >
        {/* Top area */}
        <div
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            gap: "15px",
            justifyContent: "space-between",
            margin: "10px 0 20px",
          }}
        >
          {/* Left side: Filter + Paused (if any) */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
              minWidth: "200px",
            }}
          >
            <div ref={containerRef} style={{ position: "relative" }}>
              <div
                className={css(styles.navButton)}
                onClick={() => setShowFilters((show) => !show)}
              >
                Filters <span style={{ fontSize: "12px" }}>▼</span>
              </div>
              <Filters display={showFilters} setDisplay={setShowFilters} />
            </div>

            {!isMobile && (
              <div
                className={css(styles.flakeCount)}
                onClick={() => copy("CONTRACT ADDRESS COMING SOON")}
              >
                <span style={{ fontSize: "14px" }}>{"CA: Coming Soon"}</span>
                <img
                  src={`${window.location.origin}/copying.png`}
                  alt="Copy Icon"
                  height={14}
                  style={{
                    filter: "invert(100%)",
                    opacity: 0.8,
                  }}
                />
              </div>
            )}

            {/* Show "Paused" if isPaused is true */}
            {/* {isPaused && (
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                  color: "white",
                }}
              >
                Paused
              </div>
            )} */}
          </div>

          {/* Middle: Tabs */}
          <div className={css(styles.tabContainer)}>
            {tabs.map((tabName) => {
              const isSelected = selectedTab === tabName;
              return (
                <div
                  key={tabName}
                  className={css(styles.tab, isSelected && styles.tabSelected)}
                  onClick={() => setSelectedTab(tabName)}
                >
                  {(tabName === "Pump.fun" || tabName === "DEX") && (
                    <img
                      src={`${window.location.origin}/${imagePaths[tabName]}`}
                      height={20}
                    />
                  )}
                  {tabName}
                </div>
              );
            })}
          </div>

          {/* Right side: Contract address copy */}

          <div>
            <div
              style={{
                display: "flex",
                gap: "5px",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div>
                <div style={{ fontSize: "12px", fontWeight: "600" }}>
                  Sol Buy Amount
                </div>

                <div
                  style={{
                    fontSize: "12px",
                    color: "rgba(255, 255, 255, 0.6)",
                    marginTop: "5px",
                  }}
                >
                  Amount of sol used to buy
                </div>
              </div>
              <div
                style={{
                  border: "1px solid rgba(255, 255, 255, 0.05)",
                  backgroundColor: "rgba(0, 0, 0, 0.1)",
                  display: "flex",
                  gap: "5px",
                  padding: "10px",
                  width: "120px",
                }}
              >
                <img
                  style={{ borderRadius: "100%" }}
                  width={20}
                  height={20}
                  src={`${window.location.origin}/sol.png`}
                />
                <input
                  style={{ color: "white" }}
                  placeholder="0"
                  value={sol || ""}
                  onChange={(e) => {
                    const value = e.target.value;

                    // Allow only numbers and a single decimal point
                    if (/^\d*\.?\d*$/.test(value)) {
                      setSol(value);
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            width: "100%",
            overflowY: "auto",
            scrollbarWidth: "none",
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <TraderHeader isPaused={isPaused} />
          {displayedTokens.map((coin, idx) => (
            <TraderCoin key={coin.mint} coin={coin} sol={sol} />
          ))}
        </div>

        {displayedTokens.length === 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: "20px",
              flexGrow: 1,
            }}
          >
            <div
              style={{
                color: "rgba(255, 255, 255, 0.6)",
                flexGrow: 1,
                width: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.2)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid rgba(255, 255, 255, 0.08)",
                borderRadius: "10px",
              }}
            >
              {fetchingTokens ? "Loading Dashboard..." : "No Tokens Matching"}
            </div>
          </div>
        )}
      </div>

      {!isMobile && (
        <div
          style={{
            ...oldStyles.box,
            scrollbarWidth: "none",
            justifyContent: "space-between",
            width: isMobile ? undefined : "320px",
            marginLeft: isMobile ? "0" : "20px",
            marginTop: isMobile ? "20px" : undefined,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
              gap: "5px",
              width: "100%",
              flexGrow: 1,
            }}
          >
            <Strategy sol={sol} setSol={setSol} />
            <MiniHoldings isMini={true} />
          </div>
          <ReferralWidget />
        </div>
      )}
    </>
  );
};

export default Trader;
